import React, { useState, useEffect } from "react";
import "../styles/components/NavigationButton.scss";
import { useNavigate, useLocation } from "react-router-dom";
import useActiveTab from "./state/ActiveTab";

const NavigationButton = ({
  header = "NavigationButton",
  color = "white",
  img = "",
  route = "",
  invert = false,
  handleClick,
  nonExpand = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(null); // Store the active tab (route)
  const { currentActiveButton, setCurrentActiveButton } = useActiveTab();
  // Set the active tab based on the current route
  useEffect(() => {
    if (location.pathname === `/${route}`) {
      setActiveTab(route);
    }
  }, [location.pathname, route]);

  const handleButtonClick = (e) => {
    e.preventDefault(); // Prevent default click behavior to ensure custom navigation
    setActiveTab(route); // Set the active tab to the current button's route
    setCurrentActiveButton(header);
    if (handleClick) {
      handleClick(); // Call the custom click handler if provided
    }
    if (route) {
      setTimeout(() => {
        navigate(`/${route}`); // Navigate to the specified route
      }, 200); // Delay of 200ms
    }
  };

  return (
    <div
      className={`${
        nonExpand ? "background-btn-alt" : "background-btn"
      } ${color} ${currentActiveButton === header ? "clicked" : ""}`} // Apply 'clicked' class to the active tab
      onClick={handleButtonClick}
      style={{ cursor: "pointer" }} // Ensure the button looks clickable
    >
      <div className="content">
        {img && (
          <img
            alt={`${header} Icon`} // Provide alt text for accessibility
            className={`${invert ? "invert-icon" : ""}`}
            src={img}
            width={50}
            height={30}
            style={{ objectFit: "contain" }}
          />
        )}
        <span>{header}</span>
      </div>
    </div>
  );
};

export default NavigationButton;

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../services/authentication/LoginService';
import useActiveTab from '../state/ActiveTab';
import { AuthContext } from '../../services/authentication/LoginService';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SwitchEntityService from '../../services/authentication/SwitchEntityService';
import Loader from "../../utils/Loader";
import DeregisterService from '../../services/authentication/DeregisterService';
import { UserContext } from '../../services/authentication/UserContext';
import useLogout from '../../utils/logoutFunction';
import {
  faCaretRight,
  faCaretDown,
  faSyncAlt,
  faStar,
  faUserPlus,
  faSignOutAlt,
  faTrashAlt,
  faQuestionCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import CustomDropDownItem from './CustomDropDownItem';
import ContactUsSidebar from './ContactUsSidebar';

const LogoutContainer = () => {
  const { token, setToken } = useAuth();
  const { email, password } = useContext(UserContext); 
  const { handleSignIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [logoutConfirmation, setLogoutConfirmation] = useState(false);
  const [entities, setEntities] = useState([]);
  const logoutFunction = useLogout();
  const [error, setError] = useState(false);
  const [entitiesFetched, setEntitiesFetched] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [contactUs,setContactUs] = useState(false)
  const [isLoading,setIsLoading] = useState(false)
  const toggleDropdown = async () => {
    setDropdownVisible(!isDropdownVisible);
    if (!entitiesFetched && !isDropdownVisible) {
      try {
        const data = await SwitchEntityService(token).fetchSwitchEntity();
        const entitiesArray = Object.entries(data.itemList);
        setEntities(entitiesArray);
        setEntitiesFetched(true);
      } catch (error) {
        console.error('Error fetching entities:', error);
      }
    }
  };

  // Handle entity selection with email, password, and entityNumber
  const handleEntitySelect = async (entityNumber, entityName) => {
    setDropdownVisible(false);

    const signInResult = await handleSignIn(email, password, entityNumber);
    if (signInResult.success) {
      navigateToHome(entityName, signInResult.subTaskCount);
    } else {
      alert(`Sign-in Failed: ${signInResult.error}`);
    }
  };

  const navigateToHome = (entityName, subTaskQueryCount) => {
    navigate('/patientsearch', { state: { value: entityName, subTaskQueryCount } });
  };

  const showConfirmationModal = () => {
    setIsDialogVisible(true);
  };
  
  const handleDeleteAccount = async () => {
    setIsDialogVisible(false);
    try {
      const deregisterService = DeregisterService(token);
      const deregisterResult = await deregisterService.fetchDeregister();
      if (deregisterResult.status >= 0) {
        navigate('/');
      } else {
        setError(deregisterResult.message);
      }
    } catch (error) {
      console.error("Error deregistering the user:", error);
    }
  };
  const changeLoadingState = (value) => {
    setIsLoading(value)
  }
  return (
    <div className='modalContainer'>
     <div className='d-flex flex-start border-bottom py-2'>
      <small onClick={toggleDropdown}>
        <FontAwesomeIcon icon={faSyncAlt} className="text-gray me-2" /> 
        Switch Entity
        <FontAwesomeIcon icon={isDropdownVisible ? faCaretDown : faCaretRight} className="ms-2" />
      </small>
    </div>

      {isDropdownVisible && (
        <ul style={{ listStyleType: 'none', padding: 0 }}>
        {entities.map(([entityNumber, entityName], index) => (
          <li
            key={entityNumber}
            onClick={() => handleEntitySelect(entityNumber, entityName)}
            className={`modalDropdown ${index % 2 === 0 ? 'even' : 'odd'}`}
          >
            {entityName}
          </li>
        ))}
      </ul>
      )}
      <CustomDropDownItem
      nav={()=>navigate("/favouritehistoryscreen")}
      iconName={faStar}
      iconColor={'text-gray me-2'}
      name={"Favourites"}
      />
  <CustomDropDownItem
      nav={()=>setContactUs(true)}
      iconName={faQuestionCircle}
      iconColor={'text-gray me-2'}
      name={"Help"}
  />
  <CustomDropDownItem
     nav={()=>navigate("/aboutus")}
     iconName={faInfoCircle}
     iconColor={'text-gray me-2'}
     name={"About Us"}
  
  />
  <CustomDropDownItem
      nav={showConfirmationModal}
      iconName={faTrashAlt}
      iconColor={'text-gray me-2'}
      name={"Delete Account"}
  />
  <CustomDropDownItem
       nav={() => setLogoutConfirmation(true)}
       iconName={faSignOutAlt}
       iconColor={'text-gray me-2'}
       name={"Logout"}
       borderless={true}

  />
  {contactUs && (
    <ContactUsSidebar
    changeLoading={changeLoadingState}

    closeFunction={()=>setContactUs(false)}
    />
  )}
      {logoutConfirmation && (
        <div className='confirmationModal'>
          <div className='modalContent'>
            <p>Are you sure you want to Logout?</p>
            <button onClick={logoutFunction}>Yes</button>
            <button onClick={() => setLogoutConfirmation(false)}>No</button>
          </div>
        </div>
      )}
       <Loader loading={isLoading}/>
      {/* Confirmation Modal */}
      {isDialogVisible && (
        <div className="confirmationModal">
          <div className="modalContent">
            <p>Are you sure you want to permanently delete your account?</p>
            <button onClick={handleDeleteAccount}>Yes</button>
            <button onClick={() => setIsDialogVisible(false)}>No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogoutContainer;

import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKeyboard } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import "../../styles/components/ClientPortal.scss"
const SignModal = ({ isVisible, close }) => {
  const canvasRef = useRef();
  const [activeSignOption,setActiveSignOption] = useState("draw")
  return (
    <>
      {isVisible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex justify-content-between align-items-center w-100 border-bottom">
                <div className="col-4"></div> {/* Empty column for spacing */}
                <div className="d-flex col-4 justify-content-between px-1">
                  <div onClick={()=>setActiveSignOption("type")} className={`d-flex flex-column align-items-center py-1 pointer ${activeSignOption === "type" && 'border-bottom border-primary' }`}>
                    <FontAwesomeIcon icon={faKeyboard} color="gray" />
                    <small className="signModalText" >Type</small>
                  </div>
                  <div onClick={()=>setActiveSignOption("draw")} className={`d-flex flex-column align-items-center py-1 pointer ${activeSignOption === "draw" && 'border-bottom border-primary' }`}>
                    <FontAwesomeIcon icon={faPen} color="gray" />
                    <small className="signModalText" >Draw</small>
                  </div>
                  <div onClick={()=>setActiveSignOption("image")} className={`d-flex flex-column align-items-center py-1 pointer ${activeSignOption === "image" && 'border-bottom border-primary' }`}>
                    <FontAwesomeIcon icon={faImage} color="gray" />
                    <small className="signModalText" >Image</small>
                  </div>
                </div>
                <div className="col-4 d-flex justify-content-end">
                  <input type="checkbox" />
                  <div className="d-flex justify-content-center align-items-center">
                    <small
                    
                      className="ml-1 signModalText text-center"
                    >
                      Save
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <SignatureCanvas
              ref={canvasRef}
              penColor="black"
              canvasProps={{
                className: "sigCanvas  border-bottom border-primary ",
              }}
            />
            <small
              className="text-primary text-end pointer"
              onClick={() => canvasRef.current.clear()}
              style={{fontSize:12}}
            >
              Clear
            </small>
            <div className="d-flex justify-content-end mt-2">
              <div className="p-1">
                <button className="signatureClose" onClick={close}>
                  <small className="signModalCloseText">Close</small>
                </button>
              </div>
              <div className="p-1">
                <button className="signatureSave">
                  <small className="signModalConfirmText">Save</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignModal;

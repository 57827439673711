import React from "react";
import { ErrorMessage, Field } from "formik";
const PatientList = ({
  patientListMessage,
  patientLists,
  searchQuery,
  setSearchQuery,
  setFilteredPatientLists,
}) => {
  //------------------------------------------- PATIENT LISTS ------------------------------//
  const handlePatientSearch = (text) => {
    setSearchQuery(text);
    const filteredPatients = patientLists.filter((patient) =>
      `${patient.PatientName} ${patient.PatientSurname}`
        .toLowerCase()
        .includes(text.toLowerCase())
    );

    setFilteredPatientLists(filteredPatients);
  };
  return (
    <div className="col-12 col-lg">
      <label htmlFor="patientList">Patient List</label>
      <div>
        <Field
          type="text"
          name="patientSearch"
          className="form-control"
          id="patientGroup"
          placeholder="Search Patients"
          value={searchQuery}
          onChange={(e) => handlePatientSearch(e.target.value)}
        />
           <ErrorMessage
            name="patients"
            component="div"
            className="error-text"
          />
      </div>
      {patientListMessage && (
        <span className="d-flex text-danger">{patientListMessage}</span>
      )}
    </div>
  );
};

export default PatientList;

import axios from "axios";
import PROD_API from "../baseEndpoint";

const UploadAttachmentService = (token) => {
  const handleAttachmentDetails = async (attachmentDetails) => {
    const apiUrl = `${PROD_API}upload_patient_attachments?accessToken=${token}`;

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    try {
      const response = await axios.post(apiUrl, attachmentDetails, { headers });
      // console.log("API Response:", response);

      if (response.status === 200) {
        return response.data;
      } else {
        const errorMessage = `${response.data.message}. Details: ${response.data.parm_extra}`;
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('Error during API call:', error);
      throw error;
    }
  };

  return { handleAttachmentDetails };
};

export default UploadAttachmentService;

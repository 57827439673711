import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import RatesAndPoliciesPage from "./RatesAndPoliciesPage";
import ClientPortalTabOptions from "./Reports/ClientPortalTabOptions";

const RatesAndPolicies = () => {
  return (
    <Sidebar>
      <div>

        <ClientPortalTabOptions/>
        <div className=" mt-4">
        <RatesAndPoliciesPage />
        </div>
      </div>
    </Sidebar>
  );
};

export default RatesAndPolicies;
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import vatReportService from "../../../services/Reports/vatReportService";
import monthlyService from "../../../services/Reports/monthlyReportService";
import Sidebar from "../../Sidebar/Sidebar";
import { useAuth } from "../../../services/authentication/LoginService";
import { exportToBase64, exportToExcel } from "../../../utils/excelUtils";
import { generateReportFilename } from "../../../utils/reportFileName";
import { useNavigate } from "react-router-dom";
import "jspdf-autotable";
import "../../../styles/components/BatchBilling.scss";
import GenericButton from "../../GenericButton";
import SaveReportService from "../../../services/Reports/SaveReportService";
import Loader from "../../../utils/Loader";
import { saveDocument } from "../../../services/Utils/saveDocument";
import ClientPortalTabOptions from "./ClientPortalTabOptions";
import MonthlyPDFExporter from "./MonthlyPDFExporter";

const ReportDetails = () => {
  const [error, setError] = useState("");
  const [reports, setReports] = useState([]);
  const [chartReport, setChartReports] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const navigate = useNavigate();
  const { token } = useAuth();
  const location = useLocation();
  const { reportType, month, year } = location.state || {};
  const [loading,setLoading] = useState(false)
  const { saveReport } = SaveReportService(token);
  const [ageAnalysis, setAgeAnalysis] = useState({});
  const [ageAnalysisStatus, setAgeAnalysisStatus] = useState([]);
  const [ledger, setLedger] = useState([]);
  const serviceMap = {
    "VAT Report": vatReportService(token),
    "Monthly": monthlyService(token),
    
    // "QR Report": qrCodeService(token),
  };
  const handleExport = (initial = false) => {
    const dataSheets = [
      {
        sheetName: "VAT Report",
        data: [],
      },
    ];

    let currentTaxFlag = "";
    let groupData = [];
    let groupTotal = {
      amountExcl: 0,
      amountVat: 0,
      amountIncl: 0,
    };

    reports.forEach((report) => {
      if (report.tax_flag_desc !== currentTaxFlag) {
        if (currentTaxFlag !== "") {
          groupData.push({
            "Entity No": "",
            "Entity Name": "",
            "Transaction Description": "",
            "Ledger Description": "",
            "VAT Percentage": "",
            "VAT Description": "",
            "Amount (Excl.)": groupTotal.amountExcl.toFixed(2),
            "Amount (VAT)": groupTotal.amountVat.toFixed(2),
            "Total (Incl. VAT)": groupTotal.amountIncl.toFixed(2),
          });
        }
        groupData.push({
          "Entity No": "",
          "Tax Description": report.tax_flag_desc,
          "Entity Name": "",
          "Transaction Description": "",
          "Ledger Description": "",
          "VAT Percentage": "",
          "VAT Description": "",
          "Amount (Excl.)": "",
          "Amount (VAT)": "",
          "Total (Incl. VAT)": "",
        });
        currentTaxFlag = report.tax_flag_desc;
        groupTotal = {
          amountExcl: 0,
          amountVat: 0,
          amountIncl: 0,
        };
      }
      groupData.push({
        "Entity No": report.entity_no,
        "Entity Name": report.entity_name,
        "Transaction Description":
          "Task Description: " + report.transaction_desc,
        "Ledger Description": report.ledger_desc,
        "VAT Percentage": report.vat_perc,
        "VAT Description": report.vat_desc,
        "Amount (Excl.)": parseFloat(report.amount_excl).toFixed(2),
        "Amount (VAT)": parseFloat(report.vat_amount).toFixed(2),
        "Total (Incl. VAT)": parseFloat(report.amount_incl).toFixed(2),
      });
      groupTotal.amountExcl += parseFloat(report.amount_excl);
      groupTotal.amountVat += parseFloat(report.vat_amount);
      groupTotal.amountIncl += parseFloat(report.amount_incl);
    });
    if (currentTaxFlag !== "") {
      groupData.push({
        "Entity No": "",
        "Entity Name": "",
        "Transaction Description": "",
        "Ledger Description": "",
        "VAT Percentage": "",
        "VAT Description": "",
        "Amount (Excl.)": groupTotal.amountExcl.toFixed(2),
        "Amount (VAT)": groupTotal.amountVat.toFixed(2),
        "Total (Incl. VAT)": groupTotal.amountIncl.toFixed(2),
      });
    }

    dataSheets[0].data = groupData;
    const filename = generateReportFilename(selectedDate);
    if (initial) {
      const exportExcel = exportToBase64(dataSheets);
      saveDocument(exportExcel, filename, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      return;
    }
    exportToExcel(dataSheets, filename);
  };
  const formatChartData = (data) => {
    const chartData = [];

    const breakdownData = data.find(
      (item) => item.breakdown_type === "Turnover"
    );
    const receiptingData = data.find(
      (item) => item.breakdown_type === "Receipting"
    );

    if (!breakdownData) {
      return chartData;
    }

    const monthList = breakdownData.month_list;
    Object.keys(monthList).forEach((month) => {
      if (month !== "Total" && month !== "Average") {
        chartData.push({
          month: month,
          turnover: parseFloat(monthList[month] || 0),
          receipting: parseFloat(receiptingData?.month_list[month] || 0),
        });
      }
    });

    // Convert the 'Average' field to a number
    if (monthList["Average"]) {
      monthList["Average"] = parseFloat(monthList["Average"]);
    }

    return chartData;
  };
  useEffect(() => {
    const fetchReports = async () => {
  
      if (!reportType || !serviceMap[reportType]) {
        setError("Invalid report type");
        return;
      }

      setLoading(true);
      setError("");
      try {
        const service = serviceMap[reportType];
        let response = null;

        if (reportType === "VAT Report") {
          const responseVATReport = await service.fetchVatReport(year, month);
         
          if (!responseVATReport) {
            throw new Error("Failed to fetch VAT report");
          }

          const uniqueReports = [];
          const seenDates = new Set();

          responseVATReport.vat_report_data.forEach((report) => {
            if (!seenDates.has(report.month_end_date)) {
              seenDates.add(report.month_end_date);
              uniqueReports.push(report);
            }
          });

          setReports(uniqueReports);
          setSelectedDate(responseVATReport.selected_end_date);
        } else if (reportType === "Monthly") {
          response = await service.fetchMonthlyReport(year, month);
          if (!response) throw new Error("Failed to fetch Monthly report");
          const monthlyData = response?.breakdown_by_month_by_type || [];
          const { months, turnover, receipting } = formatChartData(
            response.breakdown_by_month_by_type
          );
          setChartReports({ months, turnover, receipting });
          setReports(monthlyData);
          setSelectedDate(response.selected_end_date);
          setAgeAnalysis(response.age_analysis);
          setAgeAnalysisStatus(response.age_analysis_status);
          setLedger(response.ledger);
          setSelectedDate(response.selected_end_date);
        } else if (reportType === "QR Report") {
          // response = await service.fetchQrCodeReport();
          // if (!response || !response.qr_report_data) throw new Error("Failed to fetch QR Code report");
          // setQRReports(response.qr_report_data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, [reportType, month, year]);

  const handleViewDetails = () => {
    navigate("/report-details-page", {
      state: {
        reports: reports || [],
        reportType,
        month,
        year,
        ageAnalysis: ageAnalysis || {},
        ageAnalysisStatus: ageAnalysisStatus || [],
        ledger: ledger || [],
      },
    });
  };
  return (
    <div>
      <Sidebar heading={<h1 className="heading">{reportType}</h1>}>
        <ClientPortalTabOptions/>
        <div className="container mt-4">
          <div className="row border-bottom border-black mb-3">
            <div className="col-md-4 text-start">
              <span>Report Name</span>
            </div>
            <div className="col-md-4 text-start">
              <span>Date</span>
            </div>
            <div className="col-md-4 d-flex justify-content-end "></div>
          </div>
          <div>
            {reports.length > 0 ? (
                <div
                  className={`row mb-3 `}
                >
                  <div className="col-md-4 text-start">
                    <span>{reportType} Report</span>
                  </div>
                  <div className="col-md-4 text-start">
                    <span>{selectedDate}</span>
                  </div>
            
                  <div className="col-md-4 d-flex justify-content-end gap-2">
                    <div className="col-4">
                      {reportType === "VAT Report" ? (
                        <GenericButton
                          func={() => handleExport(false)}
                          text="Download"
                          bgColor={"bg-queryFilter"}
                          styling="p-2 w-full rounded text-center"
                        />
                      ) : (
                        <MonthlyPDFExporter
                          month={month}
                          reports={reports}
                          ageAnalysis={ageAnalysis}
                          ageAnalysisStatus={ageAnalysisStatus}
                          ledger={ledger}
                          selectedDate={selectedDate}
                          initial={false}
                        />
                      )}
                    </div>
                    
                    {reportType !== "VAT Report" && (
                      <div className="col-4">
                        <GenericButton
                          func={handleViewDetails} 
                          text="View"
                          bgColor={"bg-queryFilter"}
                          styling="p-2 w-full rounded text-center"
                        />
                      </div>
                    )}
                  </div>
                </div>
            
            ) : loading ? <Loader loading={loading}/> : (
              <div className="row mb-3">
              <div className="col-md-12 text-center">
                <span>No reports available</span>
              </div>
            </div>
            )}
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default ReportDetails;

import { exportToExcel } from '../../utils/excelUtils';

const captureChartImage = async (chartRef) => {
  try {
    // First, ensure we have a valid chart reference
    if (!chartRef?.current) {
      return null;
    }

    // Get the container div that holds the SVG
    const containerDiv = chartRef.current.container;
    if (!containerDiv) {
      return null;
    }

    // Find the SVG element within the container
    const svgElement = containerDiv.getElementsByTagName('svg')[0];
    if (!svgElement) {
      return null;
    }

    // Convert SVG to a data URL
    const svgString = new XMLSerializer().serializeToString(svgElement);
    const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
    
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(svgBlob);
    });
  } catch (error) {
    console.error('Error capturing chart image:', error);
    return null;
  }
};

export const exportWithChartImage = async (medicalAidAnalysisData, chartRef) => {
  try {
    if (!medicalAidAnalysisData?.length) {
      throw new Error('No medical aid analysis data available');
    }

    // Prepare the data for Excel
    const headers = Object.keys(medicalAidAnalysisData[0]).map(key =>
      key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')
    );

    const rows = medicalAidAnalysisData.map(rowData =>
      Object.values(rowData).map(value => 
        value !== undefined ? value : '0.00'
      )
    );

    // Try to capture the chart image
    const chartImage = await captureChartImage(chartRef);

    // Prepare the sheet data
    const sheetData = [headers, ...rows];

    // Create the export configuration
    const exportData = [{
      data: sheetData,
      sheetName: 'Medical Aid Analysis'
    }];

    // If we have a chart image, add it to the configuration
    if (chartImage) {
      exportData[0].images = [{
        src: chartImage,
        position: {
          type: 'absoluteAnchor',
          x: 10,
          y: (rows.length + 3) * 20,
          width: 600,
          height: 400
        }
      }];
    }

    // Export to Excel
    await exportToExcel(exportData, 'age_analysis_by_medical_aid.xlsx');

  } catch (error) {
    console.error('Error exporting with chart:', error);
  }
};
import { useFormikContext } from "formik";
import React, { useState } from "react";

const PatientsToRender = ({
  filteredPatientLists,
  patientLists,
  searchQuery,
  selectedPatients,
  setSelectedPatients,
}) => {
  const { setFieldValue } = useFormikContext();
  const [selectAll, setSelectAll] = useState(false);
  const patientsToRender =
    searchQuery.length > 0 ? filteredPatientLists : patientLists;

  const handleSelectAll = (checked, setFieldValue) => {
    setSelectAll(checked);
    if (checked) {
      // Select all patients
      patientsToRender.forEach((patient) => {
        if (
          !selectedPatients.some((p) => p.patient_DbId === patient.PatientID)
        ) {
          handleCheckboxChange(patient, true, setFieldValue);
        }
      });
    } else {
      // Deselect all patients
      patientsToRender.forEach((patient) => {
        handleCheckboxChange(patient, false, setFieldValue);
      });
    }
  };
  const handleCheckboxChange = (patient, newValue, setFieldValue) => {
    if (newValue) {
      setSelectedPatients((prev) => {
        const updatedList = [
          ...prev,
          { debtor_DbId: patient.DebtorID, patient_DbId: patient.PatientID },
        ];
        setFieldValue(
          "patients",
          updatedList.map((p) => p.patient_DbId)
        );
        return updatedList;
      });
    } else {
      setSelectedPatients((prev) => {
        const updatedList = prev.filter(
          (p) => p.patient_DbId !== patient.PatientID
        );
        setFieldValue(
          "patients",
          updatedList.map((p) => p.patient_DbId)
        );
        return updatedList;
      });
    }
  };
  return (
    <div className="selectedpatientscontainer">
      {/* Select All Checkbox */}
      {patientsToRender.length > 0 && (
        <div className="col selectall">
          <input
            type="checkbox"
            checked={selectAll}
            onChange={(e) => handleSelectAll(e.target.checked, setFieldValue)}
            className="checkstyle"
          />
          <span className="fw-bold">SELECT ALL</span>
        </div>
      )}

      {/* First half of patients */}
      <div style={{ display: "flex", padding: 10 }}>
        {patientsToRender
          .slice(0, Math.ceil(patientsToRender.length / 2))
          .map((patient) => (
            <div
              className="col-4"
              key={patient.PatientID}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <input
                type="checkbox"
                checked={selectedPatients.some(
                  (p) => p.patient_DbId === patient.PatientID
                )}
                onChange={(e) =>
                  handleCheckboxChange(patient, e.target.checked, setFieldValue)
                }
                className="checkstyle"
              />
              <span>
                {patient.PatientName.toUpperCase()}{" "}
                {patient.PatientSurname.toUpperCase()}
              </span>
            </div>
          ))}
      </div>

      {/* Second half of patients */}
      <div style={{ display: "flex", padding: 10 }}>
        {patientsToRender
          .slice(Math.ceil(patientsToRender.length / 2))
          .map((patient) => (
            <div
              className="col-4"
              key={patient.PatientID}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <input
                type="checkbox"
                checked={selectedPatients.some(
                  (p) => p.patient_DbId === patient.PatientID
                )}
                onChange={(e) =>
                  handleCheckboxChange(patient, e.target.checked, setFieldValue)
                }
                className="checkstyle"
              />
              <span>
                {patient.PatientName.toUpperCase()}{" "}
                {patient.PatientSurname.toUpperCase()}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PatientsToRender;

import React from "react";
import { useState } from "react";
import SideNavbar from "../../common/SideNavbar";
import { useNavigate } from "react-router-dom";
function GenerateReportSidebar({ setGenerateReport, generateReport }) {
  const navigate = useNavigate();
  const [selectedReportType, setSelectedReportType] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const [savedReports, setSavedReports] = useState([]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = [];
  const startYear = 2018; // Set the starting year to 2018
  for (let i = startYear; i <= currentYear; i++) {
    years.push(i);
  }
  // Proceed button handler
  const handleProceed = () => {
    if (selectedReportType && selectedMonth && selectedYear) {
      navigate("/reportDetails", {
        state: {
          reportType: selectedReportType,
          month: (parseInt(selectedMonth) + 1).toString(),
          year: selectedYear,
        },
      });
      setGenerateReport(false);
    } else {
      alert("Please select all fields.");
    }
  };

  const handleReportTypeChange = (e) => setSelectedReportType(e.target.value);
  const handleMonthChange = (e) => setSelectedMonth(e.target.value);
  const handleYearChange = (e) => setSelectedYear(e.target.value);
  return (
    <SideNavbar
      state={generateReport}
      heading="Generate Report"
      close={() => setGenerateReport(false)}
      proceed={handleProceed}
      buttonText="Generate"
    >
      <div className="d-flex flex-column paddingBox">
        <label>Type of Report</label>
        <select
          className="selectOption"
          value={selectedReportType}
          onChange={handleReportTypeChange}
        >
          <option value="">Select Report</option>
          <option value="Monthly">Monthly</option>
          <option value="VAT Report">VAT Report</option>
          <option value="QR Report">QR Report</option>
          <option value="Assistant Report">Assistant Report</option>
        </select>

        <label>Select Month</label>
        <select
          className="selectOption"
          value={selectedMonth}
          onChange={handleMonthChange}
        >
          <option value="">Select Month</option>
          {months.map((month, index) => (
            <option key={index} value={index}>
              {month}
            </option>
          ))}
        </select>

        <label>Select Year</label>
        <select
          className="selectOption"
          value={selectedYear}
          onChange={handleYearChange}
        >
          <option value="">Select Year</option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </SideNavbar>
  );
}

export default GenerateReportSidebar;

import { create } from 'zustand';



const useActiveHistoryReport = create((set) => ({
    activeHistoryTab: '',
    activeReportTab:'r',
    setActiveReportTab: (activeReportTab) => set({ activeReportTab }),
    setActiveHistoryTab: (activeHistoryTab) => set({ activeHistoryTab }),

  }));
  


export default useActiveHistoryReport
import React from 'react';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { getMonthName } from '../../../services/Utils/getMonthName';
import { saveDocument } from '../../../services/Utils/saveDocument';

// Helper function to draw vertical bar graph
const drawVerticalBarGraph = (doc, data, startY, maxValue, width, height) => {
    const padding = { left: 30, right: 20, top: 20, bottom: 30 };
    const graphWidth = width - padding.left - padding.right;
    const graphHeight = height - padding.top - padding.bottom;
    const startX = padding.left;
    
    // Draw Y axis
    doc.line(startX, startY + padding.top, startX, startY + height - padding.bottom);
    
    // Draw X axis
    doc.line(
      startX, 
      startY + height - padding.bottom,
      startX + graphWidth,
      startY + height - padding.bottom
    );
  
    // Draw bars
    const barWidth = 6;
    const spacing = 4;
    let xPos = startX + 10;
  
    data.forEach((item) => {
      const barHeight = (item.value / maxValue) * graphHeight;
      
      // Draw bar
      doc.setFillColor(item.frontColor || '#7999CC');
      doc.rect(
        xPos,
        startY + height - padding.bottom - barHeight,
        barWidth,
        barHeight,
        'F'
      );
      
      // Add value label above bar
      doc.setFontSize(6);
      doc.text(
        `R${Math.round(item.value).toLocaleString()}`,
        xPos + barWidth/2,
        startY + height - padding.bottom - barHeight - 5,
        { align: 'center' }
      );
      
      // Add month label if exists
      if (item.label) {
        doc.setFontSize(8);
        doc.text(
          item.label,
          xPos + barWidth/2,
          startY + height - padding.bottom + 15,
          { align: 'center' }
        );
      }
      
      xPos += barWidth + spacing;
    });
  
    // Add Y-axis labels
    doc.setFontSize(8);
    const steps = 5;
    for (let i = 0; i <= steps; i++) {
      const value = (maxValue / steps) * i;
      const yPos = startY + height - padding.bottom - (i * graphHeight / steps);
      doc.text(
        `R${Math.round(value).toLocaleString()}`,
        startX - 5,
        yPos,
        { align: 'right' }
      );
    }
  
    // Add legend
    const legendY = startY + height - 15;
    const legendSquareSize = 10;
  
    // Turnover legend
    doc.setFillColor('#7999CC');
    doc.rect(startX, legendY, legendSquareSize, legendSquareSize, 'F');
    doc.setFontSize(8);
    doc.text('Turnover', startX + legendSquareSize + 5, legendY + legendSquareSize - 2);
    
    // Receipting legend
    doc.setFillColor('#7660A9');
    doc.rect(startX + 70, legendY, legendSquareSize, legendSquareSize, 'F');
    doc.text('Receipting', startX + 70 + legendSquareSize + 5, legendY + legendSquareSize - 2);
  };

// Helper function to draw horizontal bar graph
const drawHorizontalBarGraph = (doc, turnoverValue, receiptingValue, maxValue, startY) => {
  const width = 180;
  const height = 60;
  const padding = { left: 60, right: 20, top: 10, bottom: 20 };
  const graphWidth = width - padding.left - padding.right;
  const barHeight = 12;
  const spacing = 10;
  const startX = padding.left;

  // Draw axes
  doc.line(startX, startY + padding.top, startX, startY + height - padding.bottom);
  doc.line(
    startX,
    startY + height - padding.bottom,
    startX + graphWidth,
    startY + height - padding.bottom
  );

  // Draw Turnover bar
  doc.setFillColor('#7999CC');
  const turnoverWidth = (turnoverValue / maxValue) * graphWidth;
  doc.rect(startX, startY + padding.top, turnoverWidth, barHeight, 'F');
  
  // Draw Receipting bar
  doc.setFillColor('#7660a9');
  const receiptingWidth = (receiptingValue / maxValue) * graphWidth;
  doc.rect(startX, startY + padding.top + barHeight + spacing, receiptingWidth, barHeight, 'F');

  // Add labels
  doc.setFontSize(8);
  doc.text('Turnover', startX - 5, startY + padding.top + barHeight/2, { align: 'right' });
  doc.text('Receipting', startX - 5, startY + padding.top + barHeight * 1.5 + spacing, { align: 'right' });
  
  // Add values
  doc.text(
    `R${Math.round(turnoverValue).toLocaleString()}`,
    startX + turnoverWidth + 5,
    startY + padding.top + barHeight/2
  );
  doc.text(
    `R${Math.round(receiptingValue).toLocaleString()}`,
    startX + receiptingWidth + 5,
    startY + padding.top + barHeight * 1.5 + spacing
  );

  // Add x-axis labels
  const steps = 4;
  for (let i = 0; i <= steps; i++) {
    const value = (maxValue / steps) * i;
    const xPos = startX + (graphWidth * i / steps);
    doc.text(
      `R${Math.round(value).toLocaleString()}`,
      xPos,
      startY + height - padding.bottom + 15,
      { align: 'center' }
    );
  }
};

const MonthlyPDFExporter = ({ 
  month,
  reports,
  ageAnalysis,
  ageAnalysisStatus,
  ledger,
  selectedDate,
  initial = false
}) => {
  // Helper function to format currency
  const formatCurrency = (value) => {
    if (!value) return 'R0.00';
    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    return `R${numValue.toLocaleString('en-ZA', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    let yOffset = 20;

    // Title
    doc.setFontSize(18);
    doc.text(`${getMonthName(month)} Reports`, 20, yOffset);
    yOffset += 20;

    // Performance Table
    doc.setFontSize(14);
    doc.text("Performance", 20, yOffset);
    yOffset += 10;

    // Filter months (excluding Average and Total)
    const filteredReports = reports.map(report => ({
      ...report,
      month_list: Object.fromEntries(
        Object.entries(report.month_list).filter(([key]) => key !== 'Average' && key !== 'Total')
      )
    }));

    const months = filteredReports.length ? Object.keys(filteredReports[0].month_list) : [];

    // Create performance table data
    const performanceTableHead = [["Type", ...months]];
    const performanceTableBody = filteredReports.map(report => [
      report.breakdown_type,
      ...months.map(month => 
        typeof report.month_list[month] === 'number' 
          ? report.month_list[month].toLocaleString('en-ZA', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          : report.month_list[month]
      )
    ]);

    doc.autoTable({
      head: performanceTableHead,
      body: performanceTableBody,
      startY: yOffset,
      margin: { top: 10, left: 20, right: 20 },
      theme: 'striped',
      styles: { fontSize: 8 },
      columnStyles: {
        0: { cellWidth: 30 },
      }
    });

    yOffset = doc.lastAutoTable.finalY + 20;

    // Calculate chart data
    const turnoverReport = reports.find(r => r.breakdown_type === "Turnover");
    const receiptingReport = reports.find(r => r.breakdown_type === "Receipting");

    const totalTurnover = Object.values(turnoverReport?.month_list || {})
      .reduce((sum, val) => {
        const parsedVal = parseFloat(val);
        return sum + (Number.isFinite(parsedVal) ? parsedVal : 0);
      }, 0);

    const totalReceipting = Math.abs(Object.values(receiptingReport?.month_list || {})
      .reduce((sum, val) => {
        const parsedVal = parseFloat(val);
        return sum + (Number.isFinite(parsedVal) ? parsedVal : 0);
      }, 0));

    // Add chart title and vertical bar graph
    doc.setFontSize(12);
    doc.text("Monthly Performance Graph", 20, yOffset);
    yOffset += 10;

    const verticalChartData = months.flatMap(month => {
      const turnoverValue = parseFloat(turnoverReport?.month_list[month]) || 0;
      const receiptingValue = parseFloat(receiptingReport?.month_list[month]) || 0;
      return [
        { value: turnoverValue, spacing: 10, label: month.slice(0, 3), frontColor: '#7999CC' },
        { value: Math.abs(receiptingValue), frontColor: '#7660A9' }
      ];
    });

    const maxVerticalValue = Math.max(...verticalChartData.map(d => d.value));
    drawVerticalBarGraph(doc, verticalChartData, yOffset, maxVerticalValue, 170, 80);
    yOffset += 100;

    // Add horizontal bar graph
    doc.text("Turnover vs Receipting Summary", 20, yOffset);
    yOffset += 10;

    const maxHorizontalValue = Math.max(totalTurnover, totalReceipting) * 1.2;
    drawHorizontalBarGraph(doc, totalTurnover, totalReceipting, maxHorizontalValue, yOffset);
    yOffset += 80;

    // Add tables for age analysis, status, and ledger
    [
      {
        title: "Age Analysis",
        head: [["Age Range", "Total", "Current", "30 Days", "60 Days", "90 Days", "120 Days", "150 Days", "180+ Days"]],
        body: [[
          "Total",
          formatCurrency(ageAnalysis.total),
          formatCurrency(ageAnalysis.current),
          formatCurrency(ageAnalysis.days_30),
          formatCurrency(ageAnalysis.days_60),
          formatCurrency(ageAnalysis.days_90),
          formatCurrency(ageAnalysis.days_120),
          formatCurrency(ageAnalysis.days_150),
          formatCurrency(ageAnalysis.days_180_plus)
        ]]
      },
      {
        title: "Age Analysis by Status",
        head: [["Status", "Total", "Current", "30 Days", "60 Days", "90 Days", "120 Days", "150 Days", "180+ Days"]],
        body: ageAnalysisStatus.map(status => [
          status.account_status,
          formatCurrency(status.age_analysis.total),
          formatCurrency(status.age_analysis.current),
          formatCurrency(status.age_analysis.days_30),
          formatCurrency(status.age_analysis.days_60),
          formatCurrency(status.age_analysis.days_90),
          formatCurrency(status.age_analysis.days_120),
          formatCurrency(status.age_analysis.days_150),
          formatCurrency(status.age_analysis.days_180_plus)
        ])
      },
      {
        title: "Ledger",
        head: [["Date", "Description", "Debit", "Credit", "Total", "Running Total"]],
        body: ledger.map(entry => [
          entry.date,
          entry.description,
          formatCurrency(entry.debit),
          formatCurrency(entry.credit),
          formatCurrency(entry.total),
          formatCurrency(entry.running_total)
        ])
      }
    ].forEach(section => {
      doc.setFontSize(14);
      doc.text(section.title, 20, yOffset);
      yOffset += 10;

      doc.autoTable({
        head: section.head,
        body: section.body,
        startY: yOffset,
        margin: { top: 10, left: 20, right: 20 },
        theme: 'striped',
        styles: { fontSize: 8 }
      });

      yOffset = doc.lastAutoTable.finalY + 20;
    });

    if (initial) {
      const pdfOutput = doc.output("arraybuffer");
      const uint8Array = new Uint8Array(pdfOutput);
      let binaryString = "";
      for (let i = 0; i < uint8Array.length; i++) {
        binaryString += String.fromCharCode(uint8Array[i]);
      }
      const base64PdfData = btoa(binaryString);
      saveDocument(base64PdfData, "monthlyReport.pdf", "application/pdf");
      return;
    }
    
    doc.save(`Monthly_Report_${selectedDate}.pdf`);
  };

  return (
    <button 
      onClick={generatePDF}
      className="p-2 bg-queryFilter rounded text-center"
      style={{ color: 'white' }}
      >
      Download
    </button>
  );
};

export default MonthlyPDFExporter;
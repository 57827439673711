export const getTrimmedUsername = (username) => {
    if (!username) return "";

    const nameParts = username.split(" ");
    if (nameParts.length < 2) return username;

    const firstInitial = nameParts[0].charAt(0).toUpperCase();
    const lastInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();

    return `${firstInitial}${lastInitial}`;
  };
export const getMonthName = (monthNumber) => {
    try {
      const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      
      const monthIndex = parseInt(monthNumber) - 1;
      
      if (monthIndex < 0 || monthIndex >= months.length) {
        throw new Error(`Invalid month number: ${monthNumber}`);
      }
      
      return months[monthIndex];
    } catch (error) {
      console.error('Error converting month number:', error);
      return 'Invalid Month';
    }
  };
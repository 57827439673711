import React from 'react'
import { useState } from 'react';
import GenericMonthly from './GenericMonthly';
import ValueChange from '../../utils/financialValueChange';
import SideNavbar from '../common/SideNavbar';
import EntriesDropdown from '../../utils/showEntriesUtils';
import { exportToExcel } from '../../utils/excelUtils';
import Loader from '../../utils/Loader';
import monthlyStatisticService from '../../services/FinancialDashboard/monthlyStatisticService';
import { useEffect } from 'react';
import { formatCurrency, formatCurrencyWithoutZar } from '../../services/Utils/formatCurrency';
import { useAuth } from '../../services/authentication/LoginService';
const StatisticsMonthlyTab = () => {
    const {token} = useAuth()
    const [minDate, setMinDate] = useState('');
    const { fetchAgeAnalysis } = monthlyStatisticService(token);
    const [selectedFromDate, setSelectedFromDate] = useState("");
    const [maxDate, setMaxDate] = useState('');
    const [monthlyStats, setMonthlyStats] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [months, setMonths] = useState([]);
    const [error,setError] = useState("")
    const [searchTerm, setSearchTerm] = useState('');
    const [topReferringDocs, setTopReferringDocs] = useState([]);
    const [selectedHeading, setSelectedHeading] = useState("");
    const [monthlyJournals, setMonthlyJournals] = useState(null);
    const [invoicesCreated, setInvoicesCreated] = useState(null);
    const [facilitiesData, setFacilitiesData] = useState([]);
    const [entriesPerPage, setEntriesPerPage] = useState(40);
    const [hospitalVisitsOpen,setHospitalVisitsOpen] = useState(false);
    const [monthlyBreakdown, setMonthlyBreakdown] = useState([]);
    const [monthlyBreakdownSummary, setMonthlyBreakdownSummary] = useState({});
    const [moniesReceipted, setMoniesReceipted] = useState(null);
    const [loading, setLoading] = useState(false);
    const [monthlyDiff, setMonthlyDiff] = useState({
        invoices_diff: '',
        monies_diff: '',
        journals_diff: ''
      });
      const filteredDocs = topReferringDocs.filter((doc) =>
        doc.referring_doctor.toLowerCase().includes(searchTerm.toLowerCase())
      );
      const currentDocs = filteredDocs.slice(
        (currentPage - 1) * entriesPerPage,
        currentPage * entriesPerPage
      );
      const extractYearAndMonth = (date) => {
        if (!date) return { year: null, month: null };
        const [year, month] = date.split('-'); 
        return { year, month };
        };
      const handlePageChange = (page) => {
        setCurrentPage(page);
      };
      const totalPages = Math.ceil(filteredDocs.length / entriesPerPage);
      const handleEntriesChange = (entries) => {
        setEntriesPerPage(entries);
        setCurrentPage(1); 
      };
                
    const getCurrentMonth = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0"); 
        return `${year}-${month}`;
      };
      useEffect(() => {
        const currentMonth = getCurrentMonth();
        const currentDate = new Date();
        const current12Month = currentDate.getMonth(); 
        const currentYear = currentDate.getFullYear();
        const past12Months = new Date(currentYear, current12Month - 12, 1);
        const past12MonthsYear = past12Months.getFullYear();
        const past12MonthsMonth = past12Months.getMonth(); 
        const formattedCurrentDate = `${currentYear}-${String(current12Month + 1).padStart(2, '0')}`;
        const formattedPast12Months = `${past12MonthsYear}-${String(past12MonthsMonth + 1).padStart(2, '0')}`;    
        const minDate = formattedPast12Months; 
        const maxDate = formattedCurrentDate; 
        setMinDate(minDate);
        setMaxDate(maxDate);
        setSelectedFromDate(currentMonth);  
      }, []);  
    
      const handleExportBreakdownByMonth = () => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        const filename = `Monthly_Breakdown_By_Month_${formattedDate}.xlsx`;
      
        // Transform data into array of arrays format
        const headers = ['Breakdown Type', ...months];
        const rows = monthlyBreakdown.map(breakdown => [
          breakdown.breakdown_type,
          ...months.map(month => breakdown.month_list[month] || '0.00')
        ]);
      
        const dataSheets = [
          {
            data: [headers, ...rows],
            sheetName: "Monthly Breakdown by Month"
          }
        ];
      
        exportToExcel(dataSheets, filename);
      };
      const handleExportTopRefDoc = () => {
        const topDoctorsData = currentDocs.map((doc) => ({
          Treating_Doctor: doc.treating_doctor || 'N/A',
          Amount: doc.amount || 'N/A',
          Referring_Doctor: doc.referring_doctor || 'N/A',
        }));
      
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];  
        const filename = `Top_Referring_Doctors_${formattedDate}.xlsx`;  
      
        const dataSheets = [
          {
            data: topDoctorsData,
            sheetName: "Top Referring Doctors",
          },
        ];      
        exportToExcel(dataSheets, filename);
      };
    const handleSidebarOpen = (heading) => {
        setSelectedHeading(heading);  
        let facilitiesData = [];
        if (heading === "Room Visits") {
          facilitiesData = monthlyStats.room_visits.facilities_visited;  
        } else if (heading === "Hospital Visits") {
          facilitiesData = monthlyStats.hospital_visits.facilities_visited;  
        } else if (heading === "Day Clinic Visits") {
          facilitiesData = monthlyStats.day_clinic_visits.facilities_visited;  
        }
        setFacilitiesData(facilitiesData); 
        setHospitalVisitsOpen(true);
      };
    const handleDateFromChange = (e) => {
        const date = e.target.value;
        setSelectedFromDate(date);
      };
      
      const handleFetchStats = async () => {
        setLoading(true);  
        setError(null);
      
        try {
          const { year: formattedYear, month: formattedMonth } = extractYearAndMonth(selectedFromDate);
          const data = await fetchAgeAnalysis(formattedYear, formattedMonth);
          const breakdown = data;
          if (data && data.monthly_breakdown_summary && data.monthly_breakdown_summary.month_breakdown) {
            const { invoices_created, monies_receipted, journals } = data.monthly_breakdown_summary.month_breakdown;
      
            const invoicesCreatedNumber = parseFloat(invoices_created) || 0;
            const moniesReceiptedNumber = parseFloat(monies_receipted) || 0;
            const journalsNumber = parseFloat(journals) || 0;
            setInvoicesCreated(invoicesCreatedNumber);
            setMoniesReceipted(moniesReceiptedNumber);
            setMonthlyJournals(journalsNumber);
            setMonthlyDiff({
              invoices_diff: data.monthly_breakdown_summary.invoices_diff,
              monies_diff: data.monthly_breakdown_summary.monies_diff,
              journals_diff: data.monthly_breakdown_summary.journals_diff
            })
          } else {
            // If the expected structure is missing, log an error
            console.error("Missing data structure for month_breakdown:", data);
          }
         
          const roomVisits = data?.room_visits || {};
          const breakdownByMonthByType = data?.breakdown_by_month_by_type || [];  
          const topReferringDocs = data?.top_referring_docs || [];
          console.log("111111111111", roomVisits, breakdownByMonthByType, topReferringDocs);
      
          const sampleMonths = breakdownByMonthByType.length > 0 ? Object.keys(breakdownByMonthByType[0].month_list) : [];
          setMonths(sampleMonths);
          setMonthlyBreakdown(breakdownByMonthByType);
      
          setMonthlyStats({
            room_visits: roomVisits,
            hospital_visits: data?.hospital_visits || [],
            day_clinic_visits: data?.day_clinic_visits || []
          });
          setMonthlyBreakdownSummary(data.monthly_breakdown_summary || {});
          setTopReferringDocs(topReferringDocs);
        } catch (err) {
          setError("Failed to fetch data.");
          console.error(err);
        } finally {
          setLoading(false);  
        }
      };
      useEffect(() => {
        if (selectedFromDate) {
          handleFetchStats();  
        }
      }, [selectedFromDate]);
  return (
    <div>
        <div className='d-flex mt-4 mb-2 align-items-center'>
            <div className='position-relative'>
                <input 
                  onChange={handleDateFromChange} 
                  className='absolute-element' 
                  type='month'
                  min={minDate} 
                  max={maxDate}
                />
            </div>
            <img src={require("../../assets/DateTo.png")} style={{ objectFit: "contain"}} height={80}/>   
            <div className='d-flex flex-column'>
                <span>Select Month:</span>
                <small>{selectedFromDate}</small>
            </div> 
            {/* <div className='daily-item text-white p-3 rounded ml-2' onClick={handleFetchStats}>
                <span className='text-align-center'>View</span>
            </div>  */}
      </div>
      <div className="my-4">
          {loading ? (
              <Loader loading={loading}/>
          ) : (
              <div>
              <span className='d-flex ml-2 mt-4'>
                  NOTE: Month to Date figures will show from the 3rd of every month, due to month-end processes.
              </span>
              
              {!loading && (
                  <>
                  <div className='d-flex flex-column flex-lg-row justify-content-around'>
                      {monthlyStats && (
                      <>
                  <GenericMonthly
                      invoiced={monthlyStats.room_visits.total_invoiced}
                      imagePath={require("../../assets/Room_Financial.png")}
                      heading={"Room Visits"}
                      visits={monthlyStats.room_visits.total_visits_count}
                      onClick={() => handleSidebarOpen("Room Visits")}  
                    />
                    <GenericMonthly
                      invoiced={monthlyStats.hospital_visits.total_invoiced}
                      imagePath={require("../../assets/Hospital_Financial.png")}
                      heading={"Hospital Visits"}
                      visits={monthlyStats.hospital_visits.total_visits_count}
                      onClick={() => handleSidebarOpen("Hospital Visits")}
                    />
                    <GenericMonthly
                      invoiced={monthlyStats.day_clinic_visits.total_invoiced}
                      imagePath={require("../../assets/DayClinic_Financial.png")}
                      heading={"Day Clinic Visits"}
                      visits={monthlyStats.day_clinic_visits.total_visits_count}
                      onClick={() => handleSidebarOpen("Day Clinic Visits")}
                    />
                      </>
                      )}
                  </div>

                  {/* Monthly Breakdown Section */}
                  <div className='bg-light mt-4'>
                      <div className='p-3'>
                      <h2 className='d-flex ml-4 bold-color'>Monthly Breakdown</h2>
                      <div className='d-flex flex-column flex-lg-row justify-content-around align-items-center'>
                      {monthlyBreakdownSummary && (
                          <>
                              <div className="d-flex flex-column bg-white p-4 m-4 w-50 border-rounded">
                                  <span className="d-flex">Invoice Value</span>
                                  <h1 className="d-flex text-darkblue">{formatCurrency(invoicesCreated)}</h1> 
                              </div>
                              <span> <ValueChange value={monthlyDiff.invoices_diff} /></span>
                              <div className="d-flex flex-column bg-white p-4 m-4 w-50 border-rounded">
                                  <span className="d-flex">Receipting Value</span>
                                  <h1 className="d-flex text-darkblue">{formatCurrency(moniesReceipted)}</h1>
                              </div>
                              <span><ValueChange value={monthlyDiff.monies_diff} /></span>
                              <div className="d-flex flex-column bg-white p-4 m-4 w-50 border-rounded">
                                  <span className="d-flex">Journals</span>
                                  <h1 className="d-flex text-darkblue">{formatCurrency(monthlyJournals)}</h1>
                              </div>
                              <span><ValueChange value={monthlyDiff.journals_diff} /></span>
                          </>
                      )}
                      </div>
                      </div>
                  </div>

                  {/* Hospital Visits Sidebar */}
                  <SideNavbar
                    heading={selectedHeading}
                    state={hospitalVisitsOpen}
                    close={() => setHospitalVisitsOpen(false)}
                    headingClassName="text-blue"
                  >
                    <div className="container">
                      <div className="ml-4 mt-4">
                        {facilitiesData.length > 0 ? (
                          facilitiesData.map((facilityItem, index) => (
                            <div key={index} className="d-flex flex-column align-items-start mb-3">
                              <h5 className="text-start text-blue bottomBorderBlack">{facilityItem.facility}</h5> 
                              <div className="d-flex flex-column align-items-start">
                                <h2 className="text-start text-red">{facilityItem.visits_count}</h2> 
                                <small className="text-start text-blue">Invoiced:</small>
                                <span className="text-start text-blue bold-color">{formatCurrency(facilityItem.invoiced)}</span> 
                              </div>
                            </div>
                          ))
                        ) : (
                          <div>No facilities available</div> 
                        )}
                      </div>
                    </div>
                  </SideNavbar>

                  {/* Monthly Breakdown by Month Table */}
                  <div className="d-flex align-items-center mt-4 justify-content-between">
                      <h4 className="bold-color">Monthly Breakdown by Month</h4>
                      <button onClick={handleExportBreakdownByMonth} className="download-btn">
                        <i className="fas fa-share-square"></i> <span>Export</span>
                      </button>
                    </div>

                  <div className='table-responsive'>
                      <table className="table table-bordered table-striped mt-4">
                      <thead className="thead-light">
                          <tr>
                          <th></th>
                           {months.map((month, index) => (
                              <th key={index}>{month.charAt(0).toUpperCase() + month.slice(1)}</th>
                          ))} 
                          </tr>
                      </thead>
                      <tbody>
                          {Array.isArray(monthlyBreakdown) && monthlyBreakdown.length > 0 ? (
                          monthlyBreakdown.map((breakdown, idx) => (
                              <tr key={idx}>
                              <td>{breakdown.breakdown_type}</td>
                              {months.map((month) => (
                                <td key={month}>
                                  {breakdown.month_list[month] ? formatCurrencyWithoutZar(breakdown.month_list[month]) : 'N/A'}
                                </td>
                              ))}
                              </tr>
                          ))
                          ) : (
                          <tr>
                              <td colSpan={months.length + 1}>No data available</td>
                          </tr>
                          )}
                      </tbody>
                      </table>
                  </div>

                  {/* Top Monthly Referring Doctors */}
                  {/* <div>
                  <div className="d-flex align-items-center mt-4">
                      <h4 className="bold-color">Top Monthly Referring Practitioners</h4>
                      <button onClick={handleExportTopRefDoc} className="download-btn">
                      <i className="fas fa-share-square"></i> <span>Export</span>
                      </button>
                  </div>
                      <div> */}
                      {/* <div className='d-flex flex-column flex-md-row justify-content-between w-100 mt-4'>
                          <EntriesDropdown entriesPerPage={entriesPerPage} onChange={handleEntriesChange} />
                          <div className="container">
                          <div className="row justify-content-end">
                              <div className="col-12 col-md-6">
                              <SearchInput
                                  value={searchTerm}
                                  onChange={setSearchTerm}
                                  placeholder="Search Referring Doctor"
                              />
                              </div>
                          </div>
                          </div>
                      </div> */}

                      {/* <div>
                          <table className="table mt-3">
                          <thead>
                              <tr>
                              <th scope="col">Treating Doctor</th>
                              <th scope="col">Amount</th>
                              <th scope="col">
                                  <i className="fa fa-arrow-up position-relative pointer"></i> Referring Doctor
                              </th>
                              </tr>
                          </thead>
                          <tbody>
                              {currentDocs.length > 0 ? (
                              currentDocs.map((doc, idx) => (
                                  <tr key={idx}>
                                  <td>{doc.treating_doctor || 'N/A'}</td>
                                  <td>{doc.amount || 'N/A'}</td>
                                  <td>{doc.referring_doctor || 'N/A'}</td>
                                  </tr>
                              ))
                              ) : (
                              <tr>
                                  <td colSpan="3">No data available</td>
                              </tr>
                              )}
                          </tbody>
                          </table>

                          {/* Pagination */}
                          {/* <div className="d-flex justify-content-between align-start mt-4">
                          <div className="align-start">
                              <span className="bold-color">
                              Show {1 + (currentPage - 1) * entriesPerPage} -{' '}
                              {Math.min(currentPage * entriesPerPage, filteredDocs.length)} of {filteredDocs.length} Entries
                              </span>
                          </div>
                          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                          </div>
                      </div>
                      </div> */}
                  {/* </div> */}
                  </>
              )}
              </div>
          )}
          </div>

   </div>       
  )
}

export default StatisticsMonthlyTab
import React from "react";
import SearchGuidance from "./SearchGuidance";
import ReportItem from "./Reports/ReportItem";
import Sidebar from "../Sidebar/Sidebar";
import ClientPortalTabOptions from "./Reports/ClientPortalTabOptions";

const GuidanceAndResource = () => {
  return (
    <Sidebar>
      <ClientPortalTabOptions />
      <div>
        <SearchGuidance />
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
              <ReportItem
                reportName={"OUR VISION & MISSION"}
                img={require("../../assets/ClientPortal/clientPortalgoggles.png")}
                bgColor={"light-blue"}
                optionalStyles={"border-black"}
              />
            </div>
            <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
              <ReportItem
                reportName={"OUR VISION & MISSION"}
                img={require("../../assets/ClientPortal/clientPortalHandShake.png")}
                bgColor={"light-blue"}
                optionalStyles={"border-black"}
              />
            </div>
            <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
              <ReportItem
                reportName={"OUR VISION & MISSION"}
                img={require("../../assets/ClientPortal/clientPortalGearIcon.png")}
                bgColor={"light-blue"}
                optionalStyles={"border-black"}
              />
            </div>
            <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
              <ReportItem
                reportName={"OUR VISION & MISSION"}
                img={require("../../assets/ClientPortal/clientPortalQuestion.png")}
                bgColor={"light-blue"}
                optionalStyles={"border-black"}
              />
            </div>
            <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
              <ReportItem
                reportName={"OUR VISION & MISSION"}
                img={require("../../assets/ClientPortal/clientPortalVideoPlayer.png")}
                bgColor={"light-blue"}
                optionalStyles={"border-black"}
              />
            </div>
            <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
              <ReportItem
                reportName={"OUR VISION & MISSION"}
                img={require("../../assets/ClientPortal/clientPortaluser.png")}
                bgColor={"light-blue"}
                optionalStyles={"border-black"}
              />
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default GuidanceAndResource;

import { Formik } from "formik";
import React from "react";
import GenericInput from "../GenericInput";

const PracticePolicies = () => {
  const practicePoliciesData = [
    { description: "Discount Policy", name: "discountPolicy" },
    { description: "Credits Policy", name: "creditsPolicy" },
    { description: "Write-off Policy", name: "writeoffPolicy" },
    { description: "Handover Policy", name: "handoverPolicy" },
    { description: "Handover Option", name: "handOverOption" },
    { description: "Debt Collection Agency", name: "debtCollectionAgency" },
    { description: "Other (Agency Name)", name: "other" },
    { description: "Signed Medicol Agreement", name: "signedAgreement" },
    { description: "Write-off when handed over", name: "handedoverWriteoff" },
    { description: "Auto ITC Listing", name: "itcListing" },
    { description: "Special Notes (Policies)", name: "specialNotes", other: true },
    { description: "Additional Notes", name: "additionalNotes" },
  ];

  return (
    <Formik>
      <div className="container">
        <div className="row">
          {practicePoliciesData.map((policy, index) => (
            policy?.other ? (
              // If `other` is true, display a full-width `textarea` and start a new row after it
              <React.Fragment key={index}>
                <div className="col-md-8 mb-3">
                  <label className="form-label ">{policy.description}</label>
                  <textarea  className="form-control "  name={policy.name} />
                </div>
              </React.Fragment>
            ) : (
              // Otherwise, display the GenericInput in a 4-column layout
              <div key={index} className="col-md-4 mb-3">
                <GenericInput
                  description={policy.description}
                  onChange={() => {}}
                  type="text"
                  required={false}
                  name={policy.name}
                />
              </div>
            )
          ))}
        </div>
      </div>
    </Formik>
  );
};

export default PracticePolicies;

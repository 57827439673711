import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import usePatientDetailsStore from '../state/PatientDetails';
import "../../styles/components/PatientDetailsStyle.scss"
import { useAuth } from '../../services/authentication/LoginService.js';
import MedicalSchemeOption from '../../services/Billing/MedicalSchemeOption.js';
import debounce from "lodash.debounce";
import { UserContext } from '../../services/authentication/UserContext.js';
import { useContext } from 'react';
import Sidebar from "../Sidebar/Sidebar.js"
import "../../styles/components/HomescreenStyle.scss"
import GenericInput from '../GenericInput.js';
import NavigationButton from '../NavigationButton.js';
import "../../styles/components/NavigationButton.scss"
import BillingButtons from '../common/BillingButtons.js';
import MainFooter from '../common/MainFooter.js';
import NavigateBack from '../NavigateBack.js';
function PatientDetailsOne() {

  //------------------------STATE VARIABLES-------------------------------//
  const {
    storePatientId,
    storePatientName,
    storePhysicalAddress,
    setStorePatientName,
    setPatientSurname,
    setPatientDob,
    setPhysicalAddress,
    storeContactNumber,
    setNextOfKinContact,
    setContactNumber,
    storeNextOfKinContact,
    setEmailAddress,
    setNextOfKin,
    setMedicalAidNumber
  } = usePatientDetailsStore()

  //------------------------STATE VARIABLES-------------------------------//
  const { token } = useAuth();
  const medicalSchemeOption = MedicalSchemeOption(token);
  const [isSchemeFlatListVisible, setIsSchemeFlatListVisible] = useState(false);
  const [flatListSelectPlan, setflatListSelectPlan] = useState(false)
  const [medicalSchemeData, setMedicalSchemeData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedScheme, setselectedScheme] = useState([]);
  const [copiedPlans, setCopiedPlans] = useState([])
  const optionsRef = useRef([])
  const location = useLocation()
  const navigate = useNavigate()
  const historyviewData = location?.state
  const objectMatch = {
    patientName: "name",
    patientID: "id",
    patientSurname: "surname",
    patientDOB: "dob",
    physicalAddress: "address",
    patientContactNumber: "contact_number",
    patientEmail:"patient_email",
    nextofKinTel:"nextofkin_tel",
    nextofkin:"nextofkin",
    medical_aid_number:"medical_aid_number",
    medicalAidOption:"medical_aid_option"
  }
  const [fieldModified, setFieldModified] = useState({
    patientID: false,
    patientDOB: false,  
    patientName: false,
    showpatientName: false,
    patientSurname: false,
    showpatientSurname: false,
    physicalAddress: false,
    showphysicalAddress: false,
    patientContactNumber: false,
    showpatientContactNumber: false,
    patientEmail:false,
    showpatientEmail:false,
    nextofKinTel:false,
    shownextofKinTel:false,
    nextofkin:false,
    shownextofkin:false,
    medical_aid_number:false,
    showmedical_aid_number:false,
    medical_aid_option:false,
    showmedical_aid_option:false
   

  });
  const [newFieldValues, setNewFieldValues] = useState({
    patientID: "",
    patientName: "",
    patientSurname: "",
    patientDOB: "",
    physicalAddress: "",
    patientContactNumber: "" ,
    patientEmail:"",
    nextofKinTel:"",
    nextofkin:"",
    medical_aid_number:"",
    medicalAidOption:""
  });
  const patientData = location.state?.patientData
  //------------------------VALIDATION SCHEMA FOR PATIENT DETAILS -------------------------------//
  const validationSchema = Yup.object({
    patientName: Yup.string().required('Name is required'),
    patientID: Yup.string().required('Patient ID is required'),
    patientSurname: Yup.string().required('Surname is required'),
    patientDob: Yup.string().required('Date is required'),
    // physicalAddress: Yup.string().required('Physical Address is required'),
    patientContactNumber: Yup.string()
      .required("Contact Number required")
      .matches(/^[0-9]+$/, "Only numbers are allowed")
      .matches(
        /^0[0-9]{9}$/,
        "If your number starts with a 0, the field must be 10 digits long"
      )
      .matches(/\S/, "One non-whitespace required"),
    medicalAidOption:
      patientData?.debtor_ma_schemename !== 'PRIVATE PATIENT' &&
      Yup.string()
        .trim()
        .required("Please select a Medical Aid from list")
        .matches(/^\S/, "Field must not start with a whitespace character"),

    medical_aid_plan: patientData?.debtor_ma_schemename !== 'PRIVATE PATIENT' && Yup.string()
      .trim()
      .required("Please select a plan")
      .matches(/^\S/, "Field must not start with a whitespace character"),
    medical_aid_number: patientData?.debtor_ma_schemename !== 'PRIVATE PATIENT' && Yup.string()
      .trim()
      .required("Please fill in Medical Aid number")
      .matches(/^\S/, "Field must not start with a whitespace character"),
  });

  //------------------------HANDLE MODIFIED VALUES -------------------------------//

  const handleFieldChange = (fieldName, value) => {
    let getMatched = objectMatch[fieldName]
    if (patientData[getMatched] === value) {
      setFieldModified({ ...fieldModified, [fieldName]: false, [`show${fieldName}`]: false });
      setNewFieldValues({ ...newFieldValues, [fieldName]: value });
      return
    }
    setFieldModified({ ...fieldModified, [fieldName]: false, [`show${fieldName}`]: true });
    setNewFieldValues({ ...newFieldValues, [fieldName]: value });
  };


  const handleTickClick = (fieldName, setFieldValue) => {
    if (fieldModified[fieldName]) {
      setFieldValue(fieldName, newFieldValues[fieldName]);
    }
    setFieldModified({ ...fieldModified, [fieldName]: true, [`show${fieldName}`]: false });
  };

  //Debounced search for medical schemes
  const debouncedSetSchemes = useCallback(
    debounce(async (text, setFieldValue) => {
      try {

        const schemeData = await medicalSchemeOption.fetchSchemes();
        if (Array.isArray(schemeData)) {
          const filteredSchemes = schemeData.filter((item) =>
            item.name.toLowerCase().includes(text.toLowerCase())
          );
          setMedicalSchemeData(filteredSchemes);
        }
      } catch (error) {
        setError(error.message);
      }
    }, 500),
    []
  );

  const handleSelectPlan = (item, setFieldValue) => {
    setFieldValue('medical_aid_plan', item.option_name)
    setflatListSelectPlan(false)
  };

  //handle the selection of the scheme
  const handleSelectScheme = (item, setFieldValue) => {
    if (selectedScheme.length > 0 && selectedScheme.id === item.id) {
      setselectedScheme(null);
    } else {
      // setFieldValue("selectPlan","")
      setFieldValue("medicalAidOption", item.name);
      // setselectedScheme(item);   
      // setSearchScheme(item.name);
      setIsSchemeFlatListVisible(false);
      fetchPlans(item.code)
    }
  };
  const fetchPlans = async (code) => {
    try {
      const data = await medicalSchemeOption.fetchOptions(code);
      optionsRef.current = []
      optionsRef.current.push(data)
      setCopiedPlans(data)
      setflatListSelectPlan(true)
    } catch (err) {
      setError(err.message);
    }
  }
  const debouncedSetPlans = useCallback(
    debounce(async (text, setFieldValue) => {
      try {
        const planData = optionsRef.current[0]
        if (text.length < 2) {
          setCopiedPlans(planData)
          return
        }
        else if (planData) {
          const filteredSchemes = planData.filter((item) => item.option_name.toLowerCase().includes(text.toLowerCase())
          );
          setCopiedPlans(filteredSchemes)

        }
      } catch (error) {
        setError(error.message);
      }
    }, 500),
    []
  );

  //Handle the medical scheme click
  const handleSearchSchemes = (text, setFieldValue) => {
    if (text.length >= 2) {
      debouncedSetSchemes(text);
      setIsSchemeFlatListVisible(true);
      setFieldValue("selectPlan", "")
      setflatListSelectPlan(false)
    } else {
      setMedicalSchemeData([]);
      setIsSchemeFlatListVisible(false);
    }
  };

  const genericInputChange = (inputName,text,handleChange,setValue) => {
    handleChange(inputName)(text);
    setValue(text)
    handleFieldChange(inputName, text);
  }

  //------------------------UI -------------------------------//
  return (
    <div>
      <Sidebar heading={"PATIENT DETAILS"}>

        <div className='container mt-4'>
          <NavigateBack
          text={"Patient Details"}
          />
          <BillingButtons />
          <div>
            <Formik
              initialValues=
              {{
                patientID: storePatientId ? storePatientId : patientData?.id || "",
                patientName: storePatientName ? storePatientName : patientData?.name || "",
                patientSurname: patientData?.surname || "",
                patientEmail: patientData?.patient_email || "",
                patientDob: patientData?.dob || "",
                physicalAddress: storePhysicalAddress ? storePhysicalAddress : patientData?.address || "",
                patientContactNumber: storeContactNumber ? storeContactNumber : patientData?.contact_number || "",
                mobileNumber: '',
                disciplineType: '',
                modifiedField: '',
                nextofKinTel: storeNextOfKinContact ? storeNextOfKinContact : patientData?.nextofkin_tel || "",
                nextofkin: patientData?.nextofkin || "",
                medicalAidOption: patientData?.medical_aid_option || "",
                medical_aid_plan: patientData?.medical_aid_plan || "",
                medical_aid_number: patientData?.medical_aid_number || ""
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm, setFieldError }) => {
                const showFieldsModified = [
                  fieldModified.showpatientName,
                  fieldModified.showpatientSurname,
                  fieldModified.showphysicalAddress,
                  fieldModified.showpatientContactNumber,
                  fieldModified.shownextofKinTel,
                  fieldModified.showpatientEmail
                  
                ];
                if (showFieldsModified.some(isModified => isModified)) {
                  // Prevent form submission and show an error message
                  setFieldError("modifiedField", "Please confirm all modified fields before proceeding.");
                  return;
                }
             
             
                const modifiedFields = {
                  patientName: fieldModified.patientName || values.patientName !== patientData.name,
                  patientSurname: fieldModified.patientSurname || values.patientSurname !== patientData.surname,
                  physicalAddress: fieldModified.physicalAddress || values.physicalAddress !== patientData.address,
                  patientContactNumber: fieldModified.patientContactNumber || values.patientContactNumber !== patientData.contact_number,
                  patientEmail: fieldModified.patientEmail || values.patientEmail !== patientData?.patient_email,
                  patientNextOfKinNumber: fieldModified.nextofKinTel || values.nextofKinTel !== patientData?.nextofkin_tel,
                  patientNextOfKinName: fieldModified.nextofkin || values.nextofkin !== patientData?.nextofkin,
                  // patientMedicalAid: fieldModified.patientMedicalAid || values.medicalAidOption !== patientData?.medical_aid_option,
                  // patientMedicalAidPlan: fieldModified.patientContactNumber || values.medical_aid_plan !== patientData?.medical_aid_plan,
                  patientMedicalAidNumber: fieldModified.medical_aid_number || values.medical_aid_number !== patientData?.medical_aid_number,
                };

                // Check if any field was modified
                const isAnyFieldModified = Object.values(modifiedFields).some(field => field);

                // Update the goodx_modified flag
                const goodx_modified = isAnyFieldModified ? true : false;

                // Create updated patient data object
                const updatedPatientData = {
                  ...patientData,
                  name: modifiedFields.patientName ? values.patientName : patientData.name,
                  surname: modifiedFields.patientSurname ? values.patientSurname : patientData.surname,
                  address: modifiedFields.physicalAddress ? values.physicalAddress : patientData.address,
                  contact_number: modifiedFields.patientContactNumber ? values.patientContactNumber : patientData.contact_number,

                  patient_email: modifiedFields.patientEmail ? values.patientEmail : patientData?.patient_email,
                  nextOfKinNumber: modifiedFields.patientNextOfKinNumber ? values.nextofKinTel : patientData?.nextofkin_tel,
                  nextOfKinName: modifiedFields.patientNextOfKinName ? values.nextofkin : patientData?.nextofkin,
                  // medicalAid: modifiedFields.patientMedicalAid ? values.medicalAidOption : patientData?.medical_aid_option,
                  // medicalAidPlan: modifiedFields.patientMedicalAidPlan ? values.medical_aid_plan : patientData?.medical_aid_plan,
                  medicalAidNumber: modifiedFields.medical_aid_number ? values.medical_aid_number : patientData?.medical_aid_number,
                  modifiedFields: modifiedFields,
                };
                const newModifiedData = {};
                if (modifiedFields.patientEmail) newModifiedData.patientEmail = values.patientEmail;
                if (modifiedFields.patientNextOfKinNumber) newModifiedData.patientNextOfKinNumber = values.nextofKinTel;  
                if (modifiedFields.patientNextOfKinName) newModifiedData.patientNextOfKinName = values.nextofkin;
                // if (modifiedFields.patientMedicalAid) newModifiedData.patientMedicalAid = values.medicalAidOption;
                // if (modifiedFields.patientMedicalAidPlan) newModifiedData.patientMedicalAidPlan = values.medical_aid_plan;
                if (modifiedFields.patientMedicalAidNumber) newModifiedData.patientMedicalAidNumber = values.medical_aid_number;           
                const mergedPatientData = { ...updatedPatientData, ...newModifiedData };         
                navigate('/invoicesteptwo', { state: { patientData: mergedPatientData, patientName: values.patientName, modifiedFields, goodx_modified } });
              }}
            >
              {({ isSubmitting, handleSubmit, setFieldValue, handleChange }) => (
                <Form>
                  <div className='search-padding' >
                  <div className='row mb-4 align-details'>
                      <div className='col-12 col-lg'>
                      <GenericInput
                     description={"Patient ID/Passport"}
                    required={true}
                    readOnlyInput={true}
                     type={"text"}
                     name={"patientID"}
                    />
                        <ErrorMessage name="patientID" component="div" className="error-text d-flex" />
                      </div>

                      <div className='col-12 col-lg position-relative'>
                      <GenericInput
                        description={"Patient Name"}
                        onChange={(e)=>  genericInputChange("patientName",e.target.value,handleChange,setStorePatientName)}
                        type={"text"}
                        name={"patientName"}
                        />
                        {fieldModified.showpatientName && (
                          <div className='position-absolute checkpos end-0 me-2 mt-1 pointer' onClick={() => handleTickClick("patientName", setFieldValue)} >
                            <i className='fa-regular fa-check-circle text-success'></i>
                          </div>
                        )}
                        <ErrorMessage name="patientName" component="div" className="error-text d-flex" />
                      </div>

                      <div className='col-12 col-lg position-relative'>
                      <GenericInput
                        description={"Patient Surname"}
                        onChange={(e)=>  genericInputChange("patientSurname",e.target.value,handleChange,setPatientSurname)}
                        type={"text"}
                        name={"patientSurname"}
                        />
                        {fieldModified.showpatientSurname && (
                          <div className='position-absolute checkpos end-0 me-2 mt-1 pointer' onClick={() => handleTickClick("patientSurname", setFieldValue)} >
                            <i className='fa-regular fa-check-circle text-success'></i>
                          </div>
                        )}
                        <ErrorMessage name="patientSurname" component="div" className="error-text d-flex" />
                      </div>
                    </div>

                    <div className='row mb-4 align-details'>
                      <div className='col-12 col-lg'>
                      <GenericInput
                        description={"Patient DOB (YYY-MM-DD)"}
                        onChange={(e)=>  genericInputChange("patientDob",e.target.value,handleChange,setPatientDob)}
                        type={"date"}
                        readOnlyInput={true}
                        name={"patientDob"}
                        />
                        <ErrorMessage name="patientDob" component="div" className="error-text d-flex" />
                      </div>

                      <div style={{ position: 'relative' }} className='col-12 col-lg'>
                      <GenericInput
                        description={"Physical Address"}
                        onChange={(e)=>  genericInputChange("physicalAddress",e.target.value,handleChange,setPhysicalAddress)}
                        type={"text"}
                        name={"physicalAddress"}
                        required={false}
                        />
                        {fieldModified.showphysicalAddress && (
                          <div className='position-absolute checkpos end-0 me-2 mt-1 pointer' onClick={() => handleTickClick("physicalAddress", setFieldValue)}  >
                            <i className='fa-regular fa-check-circle text-success' ></i>
                          </div>
                        )}
                        <ErrorMessage name="physicalAddress" component="div" className="error-text d-flex" />
                      </div>
                      <div className="col-12 col-lg" style={{ position: 'relative' }}>
                      <GenericInput
                        description={"Contact Number"}
                        onChange={(e)=>  genericInputChange("patientContactNumber",e.target.value,handleChange,setContactNumber)}
                        type={"tel"}
                        name={"patientContactNumber"}
                        />
                        {fieldModified.showpatientContactNumber && (
                          <div className="position-absolute checkpos end-0 me-2 mt-1 pointer" onClick={() => handleTickClick("patientContactNumber", setFieldValue)}>
                            <i className="fa-regular fa-check-circle text-success"></i>
                          </div>
                        )}
                        <ErrorMessage name="patientContactNumber" component="div" className="error-text d-flex" />
                      </div>
                    </div>
                    <div className='row mb-4 align-details'>
                      <div className='col-12 col-lg position-relative'>
                      <GenericInput
                      required={false}
                    description={"Email"}
                    onChange={(e)=>genericInputChange("patientEmail",e.target.value,handleChange,setEmailAddress)}
                    type={'text'}
                    name={"patientEmail"}
                    />
                        {fieldModified.showpatientEmail && (
                    <div className='position-absolute checkpos end-0 me-2 mt-1 pointer' onClick={() => handleTickClick("patientEmail", setFieldValue)}  >
                      <i className='fa-regular fa-check-circle text-success' ></i>
                    </div>
                        )}
                        <ErrorMessage name="patientEmail" component="div" className="error-text d-flex" />
                      </div>
                      
                      <div className='col-12 col-lg position-relative'>
                        <GenericInput
                         description={"Next of Kin Contact - Email/Cell"}
                         onChange={(e)=>genericInputChange("nextofKinTel",e.target.value,handleChange,setNextOfKinContact)}
                         type={'text'}
                         name={"nextofKinTel"}
                         required={false}
                        />
                          {fieldModified.shownextofKinTel && (
                    <div className='position-absolute checkpos end-0 me-2 mt-1 pointer' onClick={() => handleTickClick("nextofKinTel", setFieldValue)}  >
                      <i className='fa-regular fa-check-circle text-success' ></i>
                    </div>
                        )}
                      <ErrorMessage name="nextofKinTel" component="div" className="error-text d-flex" />
                      </div>
                      <div className='col-12 col-lg position-relative'>
                        <GenericInput
                        description={"Next of Kin"}
                        onChange={(e)=>genericInputChange("nextofkin",e.target.value,handleChange,setNextOfKin)}
                        type={'text'}
                        name={"nextofkin"}
                        required={false}
                        />
                            {fieldModified.shownextofkin && (
                    <div className='position-absolute checkpos end-0 me-2 mt-1 pointer' onClick={() => handleTickClick("nextofkin", setFieldValue)}  >
                      <i className='fa-regular fa-check-circle text-success' ></i>
                    </div>
                        )}
                      </div>
                    </div>
                    <div className='row mb-4 align-details'>
                      <div style={{ position: 'abosulte' }} className='col-12 col-lg'>
                        <label htmlFor="Medical Aid Option">Medical Aid Select From List</label>
                        <Field
                          type="text"
                          name="medicalAidOption"
                          className="form-control py-4"
                          id="medicalAidOption"
                          onChange={(e) => {
                            const value = e.target.value;
                            setFieldValue("medicalAidOption", value);
                            handleSearchSchemes(value, setFieldValue);
                          }}
                        />
                            {fieldModified.showmedical_aid_option && (
                          <div className='position-absolute checkpos end-0 me-2 mt-1 pointer' onClick={() => handleTickClick("medicalAidOption", setFieldValue)}  >
                            <i className='fa-regular fa-check-circle text-success' ></i>
                          </div>
                              )}
                        <ErrorMessage name="medicalAidOption" component="div" className="error-text d-flex" />
                        {medicalSchemeData && isSchemeFlatListVisible && medicalSchemeData.length > 0 && (
                          <div className="dropdown-list">
                            {/* {isLoading && <div>Loading...</div>} */}
                            <ul>
                              {medicalSchemeData.map(item => (
                                <li key={item.code} onClick={() => handleSelectScheme(item, setFieldValue)}>
                                  {item.name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div style={{ position: 'abosulte' }} className='col-12 col-lg'>
                        <label htmlFor="Medical Aid Plan">Select Plan</label>
                        <Field
                          type="text"
                          readOnly
                          name="medical_aid_plan"
                          className="form-control py-4 bg-white"
                          id="medical_aid_plan"
                          onChange={(text) => {
                            handleChange("medical_aid_plan")(text);
                          }}
                        />
                        <ErrorMessage name="medical_aid_plan" component="div" className="error-text d-flex" />
                        {flatListSelectPlan &&
                          <div className="dropdown-list">
                            <ul>
                              {copiedPlans.map((item, index) => (
                                <li onClick={() => handleSelectPlan(item, setFieldValue)} className="pointer" key={index}>
                                  {item.option_name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        }
                      </div>
                      <div className='col-12 col-lg position-relative'>                    
                        <GenericInput
                               description={"Medical Aid Number"}
                               onChange={(e)=>genericInputChange("medical_aid_number",e.target.value,handleChange,setMedicalAidNumber)}
                               type={'tel'}
                               readOnlyInput={true}
                               name={"medical_aid_number"}
                               required={true}
                        />
                        <ErrorMessage name="medical_aid_number" component="div" className="error-text d-flex" />
                      </div>
                    </div>
                  </div>

                  <ErrorMessage name="modifiedField" component="div" className="mb-3 error-text d-flex align-items-center justify-content-center" />
                  <div className='d-flex justify-content-center w-100'>
                    <div className='col-12 col-md-6 bold-txt col-lg-4'>
                        <NavigationButton 
                            handleClick={handleSubmit} 
                            header='Proceed to Invoice' 
                            img={require("../../assets/icons/ManualEntryIcon.png")} 
                            color='gray' 
                            nonExpand={true}
                        />
                    </div>
                </div>
                  <ErrorMessage name="auth" component="div" className="error-text" />
                </Form>
              )}
            </Formik>
            {/* <MainFooter/> */}
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default PatientDetailsOne;

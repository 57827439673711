import axios from "axios";
import PROD_API from "../baseEndpoint";

const QueriesHistoryService = (token) => {
  const fetchQueriesHistory = async (startDate, endDate, taskType, taskStatus, searchparm = "") => { // Accept taskType as a parameter
    try {
      // const apiUrl = PROD_API + `get_sub_task_history?accessToken=${token}&taskType=${taskType}&startDate=${startDate}&endDate=${endDate}&searchparm=${searchparm}`;
      const apiUrl = PROD_API + `get_sub_task_history?accessToken=${token}&taskType=${taskType}&taskStatus=${taskStatus}&startDate=${startDate}&endDate=${endDate}&searchparm=${searchparm}`;
      const response = await axios.post(apiUrl);
      if (response.status === 200 && response.data) {
        
        return {
          Cases: response.data.Cases || [],
          parm_extra: response.data.parm_extra || ""
        }; 
      } else {
        console.error("Failed to retrieve invoice details. Status code:", response.status);
        return {
             Cases: [], 
            parm_extra: [] };    
          }
    } catch (error) {
      console.error("Error fetching invoice details:", error);
      return {
         Cases: [],
          parm_extra: [] }; 
    }
  };
  
  return { 
    fetchQueriesHistory
   };
};

export default QueriesHistoryService;

import axios from "axios";
import PROD_API from "../baseEndpoint";

const PatientGroupListService = (token) => {
  const PatientGroupData = async (serviceCenterId) => {
    if (token) {
      try {
        const apiUrl = PROD_API + `BatchBilling/get_groups?accessToken=${token}&serviceCenterId=${serviceCenterId}`;
        const response = await axios.post(apiUrl);

        if (response.status === 200 && response.data.status === 0) {
          return response.data.batchGroups;
        } else {
            console.error(response.data.parm_extra );
            return { message: response.data.parm_extra }; 

        }
      } catch (error) {
        console.error("Failed to fetch patient group data. An error occurred:", error);
        return [];
      }
    } else {
      console.error("Token is not valid or missing.");
      return [];
    }
  };

  const PatientListData = async (serviceCenterId, selectedGroupId) => {
    if (token) {
      try {
        const apiUrl = PROD_API + `BatchBilling/get_group_patients?accessToken=${token}&serviceCenterId=${serviceCenterId}&groupId=${selectedGroupId}`;
        const response = await axios.post(apiUrl);
        if (response.status === 200 && response.data.status === 0) {
          if (response.data.batchGroupPatients.length > 0) {
            return response.data.batchGroupPatients;
          } else {
            // No data found case
            console.error("response patient",response.data.parm_extra );
            return { message: response.data.parm_extra }; 
          }
        } else {
          console.error( "No Data in Patient List", response.data.status);
          return [];
        }
      } catch (error) {
        console.error("Failed to fetch patient list data. An error occurred:", error);
        return [];
      }
    } else {
      console.error("Token is not valid or missing.");
      return [];
    }
  };

  return {
    PatientGroupData,
    PatientListData
  };
};

export default PatientGroupListService;

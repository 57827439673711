import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import DatePickerMultiple from "react-multi-date-picker";
import useServiceDateStore from "../state/ServiceDateStore";

const MultipleDatePicker = ({ dateCallBack }) => {
  const { values } = useFormikContext();
  const [multipleDates, setMultipleDates] = useState([]);
  const { dateToDisplay, setDateToDisplay } = useServiceDateStore();

  useEffect(() => {
    setMultipleDates((prevDates) => {
      if (!dateToDisplay) return prevDates;
      return [...new Set(prevDates.filter((date) => date !== dateToDisplay)), dateToDisplay];
    });
  }, [dateToDisplay]);

  useEffect(() => {
    dateCallBack(multipleDates);
  }, [multipleDates]);

  // Convert a date object to YYYY-MM-DD format with proper type checking
  const convertToNormalDate = (date) => {
    if (!date) return null;

    // Handle string dates that are already in YYYY-MM-DD format
    if (typeof date === 'string' && date.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return date;
    }

    try {
      // Handle Date objects
      if (date instanceof Date) {
        return date.toISOString().split('T')[0];
      }

      // Handle react-multi-date-picker date objects
      if (date.year && date.month && date.day) {
        const year = date.year;
        const month = (typeof date.month === 'number' 
          ? date.month 
          : date.month.number
        ).toString().padStart(2, "0");
        const day = date.day.toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      }

      return null;
    } catch (error) {
      console.error('Error converting date:', error);
      return null;
    }
  };

  const selectDates = (selectedDates) => {
    if (!Array.isArray(selectedDates)) {
      console.warn('selectedDates is not an array:', selectedDates);
      return;
    }

    // Filter out invalid dates and convert valid ones
    const normalizedSelectedDates = selectedDates
      .map((date) => convertToNormalDate(date))
      .filter((date) => date !== null);

    // Compare with previous state to identify the change
    const removedDate = multipleDates.find(
      (date) => !normalizedSelectedDates.includes(date)
    );
    const addedDate = normalizedSelectedDates.find(
      (date) => !multipleDates.includes(date)
    );

    setMultipleDates((prevDates) => {
      if (addedDate) {
        return [...prevDates, addedDate];
      } else if (removedDate) {
        return prevDates.filter((date) => date !== removedDate);
      }
      return prevDates;
    });
  };

  return (
    <div className="row align-details">
      <div className="mb-3 text-font">
        <label htmlFor="mobileNumber">Repeat Treatment for Multiple Dates</label>
        <div className="position-relative z-1">
          <input
            className="form-control bg-white"
            disabled
            value={multipleDates.join(", ")}
          />

          <DatePickerMultiple
            style={{
              border: "none",
              position: "absolute",
              top: 0,
              left: 10,
              width: "95%",
              height: "55%",
              cursor: "pointer",
              zIndex: 1000,
            }}
            mapDays={({ date }) => {
              const dateValue = convertToNormalDate(date);
              if (dateValue === dateToDisplay) {
                return {
                  disabled: true,
                  style: { color: "gray", pointerEvents: "none" },
                };
              }
              return {};
            }}
            onChange={selectDates}
            multiple
            calendarPosition="top-center"
            value={multipleDates}
            format="YYYY-MM-DD"
          />

          <img
            alt=""
            src={require("../../assets/icons/Calendar Icon.png")}
            style={{
              objectFit: "contain",
              position: "absolute",
              right: 10,
              top: 7,
            }}
            height={25}
          />
        </div>
      </div>
    </div>
  );
};

export default MultipleDatePicker;
import React, { useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import "../../../styles/components/ManualEntry.scss";
import { Formik, Field, Form, ErrorMessage } from "formik";
import usePatientDetailsStore from "../../state/PatientDetails.js";
import Sidebar from "../../Sidebar/Sidebar.js";
import MedicalAidFields from "./MedicalAidFields.js";
import NavigateBack from "../../NavigateBack.js";
const PatientManualEntry = () => {
  const {
    storePatientId,
    storePatientName,
    storePatientSurname,
    storePatientDob,
    storePhysicalAddress,
    setPatientId,
    setStorePatientName,
    setPatientSurname,
    setPatientDob,
    setPhysicalAddress,
    storePatientType,
    setStorePatientType,
    storeContactNumber,
    setContactNumber,
    storeEmailAddress,
    setEmailAddress,
    storeSelectPlan,
    storeMedicalAidSelectFromList,
    storeMedicalAidNumber,
    storeNextOfKin,
    setNextOfKin,
    storeNextOfKinContact,
    setNextOfKinContact
  } = usePatientDetailsStore();
  const navigate = useNavigate();
  const [medicalAidPatient, setMedicalAidPatient] = useState(
    storePatientType === "medicalaid" ? true : false
  );
  const [privatePatient, setPrivatePatient] = useState(
    storePatientType === "private" ? true : false
  );
  const [buttonError, setButtonError] = useState("");
  const validationSchema = Yup.object({
    patientName: Yup.string().required("Name is required"),
    patientSurname: Yup.string().required("Surname is required"),
    patientID: Yup.string()
      .required("Patient ID or Passport required")
      .test("is-id-or-passport", "Invalid ID or Passport", (value) => {
        if (value.length > 12) {
          return isSouthAfricanID(value);
        }

        if (value.length >= 6 && value.length <= 20) {
          const passportRegex = /^[A-Za-z0-9]{6,20}$/;
          return passportRegex.test(value);
        } else {
          return false;
        }
      }),
    patientDOB: Yup.string().required("Date is required"),
    // patientAddress: Yup.string().required('Address is required'),
  });

  const isSouthAfricanID = (value) => {
    const regex = /^[0-9]{13}$/;
    if (!regex.test(value)) {
      return false;
    }

    const dob = value.substring(0, 6);
    const year = parseInt(dob.substring(0, 2));
    const month = parseInt(dob.substring(2, 4));
    const day = parseInt(dob.substring(4, 6));

    if (month < 1 || month > 12 || day < 1 || day > 31) {
      // setFieldValue("patientDOB", '');
      return false;
    }

    const currentYear = new Date().getFullYear() % 100;
    if (year < 0) {
      return false;
    }

    let sum = 0;
    for (let i = 0; i < value.length; i++) {
      let digit = parseInt(value.charAt(i));
      if ((i + 1) % 2 === 0) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      sum += digit;
    }

    if (sum % 10 !== 0) {
      return false;
    }

    return true;
  };

  return (
    <div>
      <Sidebar>
        <div className="container">
          <NavigateBack text={"SEARCH PATIENT"} />
          <Formik
            initialValues={{
              patientID: storePatientId ? storePatientId : "",
              patientName: storePatientName ? storePatientName : "",
              patientSurname: storePatientSurname ? storePatientSurname : "",
              patientDOB: storePatientDob ? storePatientDob : "",
              patientAddress: storePhysicalAddress ? storePhysicalAddress : "",
              medicalAidSelectFromList: storeMedicalAidSelectFromList ? storeMedicalAidSelectFromList : "",
              selectPlan:  storeSelectPlan ? storeSelectPlan :"",
              email: storeEmailAddress ? storeEmailAddress : "",
              contactNumber: storeContactNumber ? storeContactNumber : "",
              nextOfKinContact: storeNextOfKinContact ? storeNextOfKinContact : "",
              nextofkin: storeNextOfKin ? storeNextOfKin : "",
              medicalAidNumber: storeMedicalAidNumber ? storeMedicalAidNumber : "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              if (!privatePatient && !medicalAidPatient) {
                setButtonError("Please select an option");
              } else {
                setButtonError("");
                navigate("/invoicesteptwo", {
                  state: {
                    manualPatientData: values,
                    newManualPatientData: values,
                  },
                });
              }
            }}
          >
            {({ isSubmitting, handleChange, setFieldValue, errors }) => (
              <div>
                <div className="d-flex justify-content-center mt-4">
                  <div
                    onClick={() => {
                      if (privatePatient) {
                        // Do something if it's a private patient
                      } else {
                        setMedicalAidPatient(false);
                        setStorePatientType("private");
                        setButtonError("")
                        setPrivatePatient(true);
                        setFieldValue("medicalAidNumber", "");
                        setFieldValue("medicalAidSelectFromList", "");
                        setFieldValue("selectPlan", "");
                      }
                    }}
                    className="background border-radius col-6  me-2 pointer d-flex justify-content-center  border-raduis"
                    style={{
                      backgroundColor: privatePatient ? "red" : "",
                      color: privatePatient ? "white" : "",
                    }}
                  >
                    <div className="d-flex justify-content-center align-items-center  ">
                      <img
                        width={75}
                        alt=""
                        height={75}
                        style={{
                          objectFit: "contain",
                          color: privatePatient ? "white" : "",
                        }}
                        className={`${privatePatient ? "medicalOption" : ""}`}
                        src={require("../../../assets/private.png")}
                      />
                      <span>Private Patient</span>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      if (medicalAidPatient) {
                        // Do something if it's a medical aid patient
                      } else {
                        setMedicalAidPatient(true);
                        setStorePatientType("medicalaid");
                        setPrivatePatient(false);
                        setButtonError("")
                      }
                    }}
                    className="background border-radius col-6  me-2 pointer d-flex justify-content-center align-items-center  border-raduis"
                    style={{
                      backgroundColor: medicalAidPatient ? "red" : "",
                      color: medicalAidPatient ? "white" : "",
                    }}
                  >
                    <img
                      width={75}
                      alt=""
                      height={75}
                      style={{ objectFit: "contain" }}
                      src={require("../../../assets/medical.png")}
                      className={`${!privatePatient ? "medicalOption" : ""}`}
                    />
                    <div>
                      <span>Medical Aid Patient</span>

                    </div>
                  </div>

                </div>
                <span className="text-danger">{buttonError}</span>
                <Form>
                  <div className="row">
                    <div className="'col-12 col-lg">
                      <label htmlFor="patientID">Patient ID / Passport</label>
                      <Field
                        type="text"
                        name="patientID"
                        className="form-control py-4"
                        id="patientID"
                        onChange={(e) => {
                          const text = e.target.value; // Use .value instead of .text
                          setPatientId(text);
                          handleChange("patientID")(text);
                        }}
                      />
                      <ErrorMessage
                        name="patientID"
                        component="div"
                        className="error-text d-flex"
                      />
                    </div>
                    <div className="'col-12 col-lg">
                      <label htmlFor="patientName">Patient Name</label>
                      <Field
                        type="text"
                        name="patientName"
                        className="form-control py-4"
                        id="patientName"
                        onChange={(e) => {
                          const value = e.target.value; // Use .value instead of .text
                          setStorePatientName(value);
                          handleChange("patientName")(value);
                        }}
                      />
                      <ErrorMessage
                        name="patientName"
                        component="div"
                        className="error-text d-flex"
                      />
                    </div>
                    <div className="'col-12 col-lg">
                      <label htmlFor="patientSurname">Patient Surname</label>
                      <Field
                        type="text"
                        name="patientSurname"
                        className="form-control py-4"
                        id="patientSurname"
                        onChange={(e) => {
                          const value = e.target.value; // Use .value instead of .text
                          setPatientSurname(value);
                          handleChange("patientSurname")(value);
                        }}
                      />
                      <ErrorMessage
                        name="patientSurname"
                        component="div"
                        className="error-text d-flex"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="'col-12 col-lg">
                      <label htmlFor="patientDOB">
                        Patient DOB (yyyy-mm-dd)
                      </label>
                      <Field
                        type="date"
                        name="patientDOB"
                        className="form-control py-4"
                        id="patientDOB"
                        onChange={(e) => {
                          const value = e.target.value; // Use .value instead of .text
                          setPatientDob(value);
                          handleChange("patientDOB")(value);
                        }}
                      />
                      <ErrorMessage
                        name="patientDOB"
                        component="div"
                        className="error-text d-flex"
                      />
                    </div>

                    <div className="'col-12 col-lg">
                      <label htmlFor="patientAddress">Physical Address</label>
                      <Field
                        type="text"
                        name="patientAddress"
                        className="form-control py-4"
                        id="patientAddress"
                        onChange={(e) => {
                          const value = e.target.value; // Use .value instead of .text
                          setPhysicalAddress(value);
                          handleChange("patientAddress")(value);
                        }}
                      />
                      <ErrorMessage
                        name="patientAddress"
                        component="div"
                        className="error-text d-flex"
                      />
                    </div>
                    <div className="'col-12 col-lg">
                      <label htmlFor="contactnumber">Contact Number</label>
                      <Field
                        type="contactNumber"
                        name="contactNumber"
                        className="form-control py-4"
                        id="contactNumber"
                        onChange={(e) => {
                         
                          const value = e.target.value; // Use .value instead of .text
                          handleChange("contactNumber")(value);
                          setContactNumber(value)
                        }}
                      />
                      <ErrorMessage
                        name="contactNumber"
                        component="div"
                        className="error-text d-flex"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="'col-12 col-lg">
                      <label htmlFor="Email">Email Address</label>
                      <Field
                        type="email"
                        name="email"
                        className="form-control py-4"
                        id="email"
                        onChange={(e) => {
                     
                          const value = e.target.value; // Use .value instead of .text
                          setEmailAddress(value)
                          handleChange("email")(value);
                        }}
                      />
                    </div>
                    <div className="'col-12 col-lg">
                      <label htmlFor="Next of Kin Tel">
                        Next of Kin Contact Number
                      </label>
                      <Field
                        type="tel"
                        name="nextOfKinContact"
                        className="form-control py-4"
                        id="nextOfKinContact"
                        onChange={(e) => {
                          const value = e.target.value; // Use .value instead of .text
                          setNextOfKinContact(value)
                          handleChange("nextOfKinContact")(value);
                        }}
                      />
                      <ErrorMessage
                        name="nextOfKinContact"
                        component="div"
                        className="error-text d-flex"
                      />
                    </div>
                    <div className="'col-12 col-lg">
                      <label htmlFor="Next of Kin">Next of Kin</label>
                      <Field
                        type="text"
                        name="nextofkin"
                        className="form-control py-4"
                        id="nextofkin"
                        onChange={(e) => {
                          const value = e.target.value; // Use .value instead of .text
                          setNextOfKin(value)
                          handleChange("nextofkin")(value);
                        }}
                      />
                    </div>
                  </div>
                  <MedicalAidFields />
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="col-4 formButtonPatientDetails"
                    >
                      <div className="text-black d-flex align-items-center justify-content-center">
                        <img
                          width={50}
                          height={35}
                          alt=""
                          style={{ objectFit: "contain" }}
                          src={require("../../../assets/icons/ManualEntryIcon.png")}
                        />
                        <span style={{ fontWeight: "bold" }}>
                          Proceed to Invoice
                        </span>
                      </div>
                    </button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </Sidebar>
    </div>
  );
};

export default PatientManualEntry;

import React, { useState } from "react";
import NavigationButton from "../NavigationButton";
import PracticePolicies from "./PracticePolicies";
import GenericButton from "../GenericButton";
import GenericModal from "../GenericModal";
import SignModal from "./SignModal";
import RateSetup from "./RateSetup";
const RatesAndPoliciesPage = () => {
  const ratesAndpolicies = [
    //dummy data
    {
      DateFrom: "01/01/2024",
      ArrangementType: "Payment Arrangement",
      Option: "Polmed Specialist",
    },
    {
      DateFrom: "01/01/2024",
      ArrangementType: "Payment Arrangement",
      Option: "Polmed Specialist",
    },
    {
      DateFrom: "01/01/2024",
      ArrangementType: "Payment Arrangement",
      Option: "Polmed Specialist",
    },
    {
      DateFrom: "01/01/2024",
      ArrangementType: "Payment Arrangement",
      Option: "Polmed Specialist",
    },
  ];
  const [activeTab, setActiveTab] = useState("APA");
  const [modalVisible, setModalVisible] = useState(false);
  const [signModal, setSignModal] = useState(false);
  return (
    <div className="container">
      <div className=" d-flex flex-column justify-content-between">
        <div className="responsive-flex">
          <NavigationButton
            color="profileColor"
            handleClick={() => setActiveTab("APA")}
            header="Active Payment Arrangements"
            img={require("../../assets/ClientPortal/activePaymentArrangements.png")}
          />

          <NavigationButton
            img={require("../../assets/ClientPortal/clientPortalRatesandPolicies.png")}
            handleClick={() => setActiveTab("MPP")}
            invert={true}
            header="My Practice Policies"
          />
          <NavigationButton
            img={require("../../assets/ClientPortal/clientPortalrateSetup.png")}
            header="My Rate Setup"
            handleClick={() => setActiveTab("MRS")}
          />
        </div>
        {activeTab === "APA" ? (
          ratesAndpolicies.map((item, idx) => (
            <div
              key={idx}
              className={idx % 2 === 0 ? "bg-white p-3" : "bg-light p-3"}
            >
              {idx === 0 && (
                <div className="d-flex justify-content-between">
                  <span className="col-4 text-start mb-2 text-header">
                    Date From
                  </span>
                  <span className="col-4 text-start mb-2 text-header">
                    Arrangement Type
                  </span>
                  <span className="col-4 text-start mb-2 text-header">
                    Option
                  </span>
                </div>
              )}
              <div className="d-flex justify-content-between">
                <span className="col-4 text-start">{item.DateFrom}</span>
                <span className="col-4 text-start">{item.ArrangementType}</span>
                <span className="col-4 text-start">{item.Option}</span>
              </div>
            </div>
          ))
        ) : activeTab === "MPP" ? (
          <>
            <PracticePolicies />
          </>
        ) : (
          <RateSetup />
        )}
        <SignModal isVisible={signModal} close={() => setSignModal(false)} />

        <GenericModal
          close={() => setModalVisible(false)}
          conditional={modalVisible}
          description={
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud"
          }
          proceedText="Send"
          closeText="Close"
        />
        <div className="d-flex justify-content-center align-items-center p-4">
          <GenericButton
            func={() => setSignModal(!signModal)}
            textStyles={"text-white"}
            styling={
              "d-flex bg-red p-3 rounded justify-content-center align-items-center "
            }
            text={"Request Change"}
          />
        </div>
      </div>
    </div>
  );
};

export default RatesAndPoliciesPage;

import React, { useEffect, useState } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ServiceCenter from "../Invoices/ServiceCenter.js";
import "../../styles/components/HomescreenStyle.scss";
import { useAuth } from "../../services/authentication/LoginService.js";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Sidebar from "../Sidebar/Sidebar.js";
import "../../styles/components/NavigationButton.scss";
import usePatientDetailsStore from "../state/PatientDetails.js";
import NavigationButton from "../NavigationButton.js";
import "../../styles/components/BatchBilling.scss";
import useServiceCenterCodesStore from "../state/ServiceCenterCodeStore.js";
import useServiceDateStore from "../state/ServiceDateStore.js";
import BillingButtons from "../common/BillingButtons.js";
import useTreatingDoctorStore from "../state/TreatingDoctorStore.js";
import ProcessBatchService from "../../services/BatchBillingService/ProcessBatchService.js";
import NavigateBack from "../NavigateBack.js";
import TreatingDoctor from "../Invoices/TreatingDoctor.js";
import ServiceDateField from "../Invoices/ServiceDate.js";
import Loader from "../../utils/Loader.js";
import PatientsToRender from "./PatientsToRender.js";
import GroupSelection from "./GroupSelection.js";
import PatientList from "./PatientList.js";

const BatchBilling = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAuth();
  const navigate = useNavigate();
  const { defaultTreatingDoc, defaultTreatingDocID } = useTreatingDoctorStore();
  const { selectedServCenterId } = useServiceCenterCodesStore((state) => state);
  const { dateToDisplay } = useServiceDateStore();
  const [patientListMessage, setPatientListMessage] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [patientLists, setPatientLists] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const processBatchService = ProcessBatchService(token);
  //------------------------------ PATIENT LISTS CONST ---------------------------------//
  const [filteredPatientLists, setFilteredPatientLists] = useState([]);
  const { resetPatientDetails } = usePatientDetailsStore((state) => ({
    resetPatientDetails: state.resetPatientDetails,
  }));

  const { serviceCenterData, isServCAdIconClicked } =
    useServiceCenterCodesStore();

  const validationSchema = Yup.object({
    serviceCenter: !isServCAdIconClicked
      ? Yup.string()
          .test(
            "is-valid-plan",
            "Please select a valid Service Center from the dropdown",
            function(value) {
              // Accept if we have a valid selectedServCenterId
              if (selectedServCenterId) {
                return true;
              }

              if (!value) return false;
              if (!serviceCenterData?.length) return false;

              const trimmedValue = value.trim();
              return serviceCenterData.some(option => 
                option.name.toLowerCase() === trimmedValue.toLowerCase()
              );
            }
          )
          .required("Service Center is required")
      : Yup.string().required("Service Center is required"),
    serviceDate: Yup.string().required("Service Date is required"),
    servCenterName:
      isServCAdIconClicked &&
      Yup.string()
        .required("Field is required")
        .matches(/\S/, "One non-whitespace required"),
    patientGroup: Yup.string().required("Patient Group is required"),
    patients: Yup.array()
      .min(1, "At least one patient must be selected")
      .required("Field is required"),
  });
  useEffect(() => {
    resetPatientDetails();
  }, []);

  const setPatients = (value) => {
    setSelectedPatients(value);
  };
  const setListsPatient = (value) => {
    setPatientLists(value);
  };
  const setListMessage = (value) => {
    setPatientListMessage(value);
  };
  const groupIdSet = (value) => {
    setSelectedGroupId(value);
  };
  const setQuerySearch = (value) => {
    setSearchQuery(value);
  };
  const setFilterPatient = (value) => {
    setFilteredPatientLists(value);
  };
  const handleSubmit = async (values, actions) => {
    // Set loading to true when the submit action is initiated
    setIsLoading(true);

    const batchDetails = {
      treating_doctorId: defaultTreatingDocID,
      service_centerId: selectedServCenterId,
      service_date: dateToDisplay,
      patient_groupId: selectedGroupId,
      batch_patients: selectedPatients.map((patient) => ({
        debtor_DbId: patient.debtor_DbId,
        patient_DbId: patient.patient_DbId,
      })),
    };
    try {
      const response = await processBatchService.ProcessBatchData(batchDetails);
      // Handle response data
      const { parm_extra } = response;
      // Navigate to confirmation page with success message
      navigate("/batchBillingconfirmation", {
        state: {
          batchDetails,
          message: "Batch details submitted successfully!",
          parm_extra: parm_extra,
        },
      });
    } catch (error) {
      // Handle any errors that occur during the submission
      console.error("Failed to submit batch details:", error.message);
      alert("Failed to submit batch details. Please try again later.");
    } finally {
      actions.setSubmitting(false);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Sidebar heading={"BATCH BILLING"}>
        <div className="container mt-4">
          <NavigateBack text={"Batch Billing"} />
          <BillingButtons />
          <Formik
            initialValues={{
              serviceCenter: "",
              treatingDoctor: defaultTreatingDoc || "",
              servCenterName: "",
              servCenterType: "",
              serviceCenterId: "",
              patientGroup: "",
              patients: [],
              serviceDate: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, handleSubmit, values, errors }) => (
              <Form onSubmit={handleSubmit} className="search-padding">
                <div className="row">
                  <TreatingDoctor treatingDoctor={defaultTreatingDoc} />
                  <div className="col-12 col-lg">
                    <ServiceCenter
                      serviceCenterType={values.servCenterType}
                      serviceCenterName={values.servCenterName}
                      serviceCenterDefault={values.serviceCenter}
                      hasAuth={false}
                    />
                  </div>
                  <GroupSelection
                    setSelectedPatients={setPatients}
                    selectedServCenterId={selectedServCenterId}
                    setPatientLists={setListsPatient}
                    setPatientListMessage={setListMessage}
                    setSelectedGroupId={groupIdSet}
                  />
                </div>

                <div className="row">
                  <PatientList
                    patientListMessage={patientListMessage}
                    patientLists={patientLists}
                    searchQuery={searchQuery}
                    setSearchQuery={setQuerySearch}
                    setFilteredPatientLists={setFilterPatient}
                  />
                  <div className="col-12 col-lg">
                    <p className="mt-5">
                      Patients Checked:
                      <span className="checkedcolor">
                        {" "}
                        {selectedPatients.length}
                      </span>
                    </p>
                  </div>

                  <ServiceDateField
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <Loader loading={isLoading} />
                <PatientsToRender
                  filteredPatientLists={filteredPatientLists}
                  patientLists={patientLists}
                  searchQuery={searchQuery}
                  selectedPatients={selectedPatients}
                  setSelectedPatients={setPatients}
                />
                <div>
                  <div className="d-flex justify-content-center">
                    <div className="w-25">
                      <NavigationButton
                        color="profileColor"
                        header={"Submit"}
                        handleClick={handleSubmit}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Sidebar>
    </div>
  );
};

export default BatchBilling;

import { useFormikContext } from 'formik';
import React from 'react'

const TimeFromTo = ({timeFromToCodes,item,handleSelectTimes,times}) => {
    const {  setFieldValue,errors,touched } = useFormikContext()
  return (
<>
{(timeFromToCodes.includes(item.code) || item.code === "0011A") && (
              <div>
                <label>
                  {item.code === "0011A"
                    ? "Theatre Duration Time (From and To)"
                    : "Time (From and To)"}
                </label>
                <div style={{ flex: 1 }}>
                  <div className="d-flex justify-content-between">
                    
                    <label>From:</label>
                    <div>
                    <input
                      onChange={(e) => {
                        const value = e.target.value;
                        handleSelectTimes(value, item.code,"from",setFieldValue);
                        // setFieldValue("fromTime", value)
                      }}
                      value={times[item.code]?.fromTime || ""}
                      type="time"
                    />
                    {touched.fromTime && errors.fromTime && (
                        <div className="error-text">{errors.fromTime}</div>
                      )}
                      </div>
                  </div>
           
                  <div className="d-flex justify-content-between mt-4" >
                    <label>To:</label>

                    <div>
                    <input
                      value={times[item.code]?.toTime || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        handleSelectTimes(value, item.code,"to",setFieldValue);
                        // setFieldValue("toTime", value)
                      }}
                      type="time"
                    />
                                      {touched.toTime && errors.toTime && (
                        <div className="error-text">{errors.toTime}</div>
                      )}
                      </div>
                  </div>
                </div>
              </div>
            )}
</>
  )
}

export default TimeFromTo
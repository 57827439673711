import React from "react";
import "../styles/components/GeneralStyles.scss";
const GenericSearch = ({
  values = "",
  handleChange = () => {},
  clickFunc = () => {},
  placeholder = "Search",
}) => {
  return (
    <div className="lightColor centered-items mt-2 w-100 search-categories">
      <div className="position-relative w-100 ">
        <input
          type="text"
          className="border border-light lightColor input-custom w-100 search-patient"
          placeholder={placeholder}
          aria-label="Search Patient"
          onChange={handleChange}
          onKeyDown={clickFunc}
          value={values}
        />
        {/* absolute-pos */}
        <i onMouseDown={clickFunc} onClick={clickFunc} className="fa fa-search searchBar pointer " style={{ color: "#000" }} />
      </div>
    </div>
  );
};

export default GenericSearch;

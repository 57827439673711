// currencyFormatter.js
export const formatCurrency = (value) => {
    if (!value) return 'R 0.00';
    
    const number = parseFloat(value);
    return `R ${number.toLocaleString('en-ZA', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;
  };
  
  export const formatCurrencyWithoutZar = (value) => {
    if (!value) return '0.00';
    
    const number = parseFloat(value);
    return number.toLocaleString('en', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };
  
  console.log(formatCurrency(3397102.49));  // "R 3 397 102.49"
  console.log(formatCurrency());            // "R 0.00"
  console.log(formatCurrency(1000000));     // "R 1 000 000.00"
import React, { useState } from "react";
import { ErrorMessage } from "formik";
import "../../styles/components/invoice.scss";
import SideNavbar from "../common/SideNavbar";
import useServiceDateStore from "../state/ServiceDateStore";

const ServiceDateField = ({
  values,
  setFieldValue,
  setIsSidebarOpenAddFavourites = () => {},
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { setDateToDisplay, dateToDisplay } = useServiceDateStore();
  const [selectedDate, setSelectedDate] = useState(values.serviceDate || "");

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setIsSidebarOpenAddFavourites(false);
  };

  const closeSidebar = () => {
    setIsSidebarOpenAddFavourites(false);
    setIsSidebarOpen(false);
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    setSelectedDate(date);
  };

  const saveDate = () => {
    closeSidebar();
    setDateToDisplay(selectedDate);
    setFieldValue("serviceDate", selectedDate);
  };

  return (
    <div className="col-12 col-lg text-font">
      <label htmlFor="serviceDate">
        Service Date<span className="error-text">*</span>
      </label>

      <div className="position-relative">
        <input
          type="text"
          name="serviceDate"
          className="form-control bg-field text-font pointer"
          id="serviceDate"
          onClick={toggleSidebar}
          value={dateToDisplay || ""}
          readOnly
          placeholder="Select a Service Date"
        />

        <img
          src={require("../../assets/icons/Calendar Icon.png")}
          alt="Calendar Icon"
          className="calendar-icon"
        />
      </div>

      <ErrorMessage
        name="serviceDate"
        component="div"
        className="error-text d-flex"
      />

      <SideNavbar
        proceed={saveDate}
        close={closeSidebar}
        state={isSidebarOpen}
        heading={"Service Date"}
        headerContent={
          <div className="date-picker-container p-2">
            <label htmlFor="sidebarServiceDate" className="text-font">Select a Date:</label>
            <input
              type="date"
              onClick={(e) => e.currentTarget.showPicker()}
              id="sidebarServiceDate"
              className="form-control pointer"
              value={selectedDate}
              onChange={handleDateChange}
              max={new Date().toISOString().split("T")[0]} // Restrict to past and current dates
            />
          </div>
        }
      ></SideNavbar>
    </div>
  );
};

export default ServiceDateField;
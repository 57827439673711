import React from "react";
import { getTrimmedUsername } from "../../utils/trimmedUsername";
import CustomScroll from "../common/CustomScroll";
const SubTaskComment = ({ subTasks, initials }) => {
  return (
    <>
      {subTasks.comments.map((comment) => (
        <div key={comment.CommentID} className={`comment-container mt-4`}>
          <div >
            {comment.CommentIsInternal === "1" ? (
              <>
                <div className="profile-circle-internal">
                  <img
                    src={require("../../assets/xp-icon-new.png")}
                    alt="Profile"
                    width={30}
                    height={30}
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <div style={{ flex: 1, marginLeft: "10px" }}>
                  <small className="d-flex">{comment.CommentCreatedDate}</small>
                  <div className="comment-box">
                    <p className="comment-desc">{comment.CommentDesc}</p>
                  </div>
                </div>
              </>
            ) : (
              <div className="d-flex flex-row justify-content-between">
                <div style={{ flex: 1 }}>
                  <small className="d-flex text-secondary">
                    {comment.CommentCreatedDate}
                  </small>
                  <CustomScroll smallerScroll={true} maxHeight="20vh">
                  <div className="comment-box">
                    <p className="comment-desc">{comment.CommentDesc}</p>
                  </div>
                  </CustomScroll>

                </div>
            
                <div className="profile-circle-external">
                  <p className="profile-initials">{initials}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default SubTaskComment;

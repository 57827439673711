import React from "react";
import "../../styles/components/SideNav.scss";
import CustomScroll from "./CustomScroll";
function SideNavbar({
  heading = "Default Heading",
  className = "",
  children,
  notice = false,
  state,
  proceed = () => {},
  close,
  buttonText = "Proceed",
  headerContent
}) {
  return (
    <div className={`absolute-notes ${state ? "open" : ""} ${className || ""}`}>
      <CustomScroll maxHeight={"100vh"} smallerScroll={true}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "95vh",
          }}
        >
          <div>
            <div className="d-flex justify-content-start mt-3">
              <span className="bold sidebar-title text-blue">{heading}</span>
            </div>

            {notice && (
              <div className="d-flex justify-content-start ">
                <div className="custom-margin">
                  <img
                    src={require("../../assets/icons/Info Icon.png")}
                    style={{ objectFit: "contain" }}
                    width={20}
                    alt="Info Icon"
                  />
                </div>
                <div className="text-start">
                  <small className="align-details">
                    Record and save patient encounter details on your device,
                    securely stored in your clinical notes history for easy
                    access and reference.
                  </small>
                </div>
    
              </div>
            )}
            {headerContent && headerContent}
          </div>
          {children}
          <div>
            <div className="line"></div>
            <div className="d-flex justify-content-end align-items-end mr-1">
              <button
                type="button"
                onClick={() => proceed()}
                className="align-items-end favourite-add"
              >
                {buttonText}
              </button>
              <button
                type="button"
                onClick={() => close()}
                className="align-items-end favourite-close"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </CustomScroll>
    </div>
  );
}
export default SideNavbar;

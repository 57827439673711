import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
///--services--
import { useAuth } from "../../services/authentication/LoginService";
import useProcedureCodesStore from "../state/ProcedureCodeStore.js";
import * as Yup from "yup";
import ProcessBillingRequestService from "../../services/Billing/ProcessBillingRequestService";
import ICD10 from "./ICD10Codes.js";
import AddClinicalNoteService from "../../services/ClinicalNotes/AddClinicalNoteService.js";
import TreatingDoctor from "./TreatingDoctor.js";
import ProcedureCode from "./ProcedureCodes.js";
import useServiceCenterCodesStore from "../state/ServiceCenterCodeStore.js";
import { useLocation } from "react-router-dom";
import { Toast, ToastContainer } from "react-bootstrap";
import NappiCodeSelector from "./NappiCodes.js";
import Sidebar from "../Sidebar/Sidebar.js";
import useModifiersStore from "../state/ModifiersStore.js";
import ServiceCenter from "./ServiceCenter.js";
import ReferringDoctor from "./ReferringDoctor.js";
import useTreatingDoctorStore from "../state/TreatingDoctorStore.js";
import specialties from "../../assets/Codes/specialties.json";
import ServiceDateField from "./ServiceDate.js";
import { useFavouritesService } from "./Constants/FavouritesConstants.js";
import useServiceDateStore from "../state/ServiceDateStore.js";
import AddButton from "./AddButton.js";
import NavigateBack from "../NavigateBack.js";
import AddClinicalNote from "../AddClinicalNote.js";
import useReferringDoctorStore from "../state/ReferringDoctorStore.js";
import BillingFavourites from "../BillingFavourites.js";
import MultipleDatePicker from "./MultipleDatePicker.js";
import useActiveTab from "../state/ActiveTab.js";
import GenericButton from "../GenericButton.js";
import useNappiCodeStore from "../state/NappiCodeStore.js";
import useICD10CodesStore from "../state/ICD10CodesStore.js";
import Loader from "../../utils/Loader.js";
// import "../../styles/components/invoice.scss";
function InvoiceStepTwo() {
  const navigate = useNavigate();
  const { token } = useAuth();
  const location = useLocation();
  const currentDate = new Date().toISOString();
  const [show, setShow] = useState(false);
  const [filteredSpecialties, setfilteredSpecialties] = useState(
    specialties.specialty
  );
  const addClinicalNoteService = AddClinicalNoteService(token);
  //DATA LOCATION
  const reInvoice = location?.state?.reInvoice;
  const selectedFileData = location?.state?.selectedFile;
  const patientFullName = location?.state?.patientFullName;
  const patientData = location?.state?.patientData;
  const newManualPatientData = location?.state?.newManualPatientData;
  const goodx_modified = location?.state?.goodx_modified;
  const patient_name = location?.state?.patientName;
  //Process Billing
  const processBillingRequestService = ProcessBillingRequestService(token);
  //------------------------GENERAL CONST -------------------------------//
  const [clinicalNote, setClinicalNote] = useState("");
  const [toggleClinicalNote, setToggleClinicalNote] = useState(false);
  const [hasSelectedProcedureCode, setHasSelectedProcedureCode] =
    useState(false);
  const [selectedCode, setSelectedCode] = useState("");

  const {
    selectedProcedureCodes,
    selectedICD10Codes,
    selectedNappiCodes,
    selectedRefDoc,
    modifier,
    setProcedureCodeFlatListVisible,
  } = useProcedureCodesStore();

  const { setNappiCodeFlatListVisible } = useNappiCodeStore();
  const { setIsICD10FlatListVisible } = useICD10CodesStore();
  const { selectedTreatingDoc, defaultTreatingDoc, defaultTreatingDocID } =
    useTreatingDoctorStore();
  const { dateToDisplay } = useServiceDateStore();
  const {
    surnameAndName,
    isRefDocAdIconClicked,
    practiceNumber,
    specialityCode,
    setIsRefDocFlatListVisible,
  } = useReferringDoctorStore();
  const {
    serviceCenterData,
    selectedServCenter,
    servicecenterType,
    isServCenterFlatListVisible,
    authorizationNumber,
    setIsServCenterFlatListVisible,
    isServCAdIconClicked,
    serviceCenterManualName
  } = useServiceCenterCodesStore();
  const {
    showAssistant,
    searchTermSpeciality,
    nameSurname,
    toTime,
    fromTime,
    times,
    selectedAssistant,
    selectedValues,
    filteredAssistants,
  } = useModifiersStore();
  const [multiDates, setMultiDates] = useState([]);

  const dateCallBack = (date) => {
    setMultiDates(date);
  };
  //------------------------FAVOURITES ------------------------------------//
  const { isSidebarOpenAddFavourites, setIsSidebarOpenAddFavourites } =
    useFavouritesService();
  const { setIsBilling, isBilling } = useActiveTab();
  //------------------------------------- COMMON CALLS ----------------------------------//
  useEffect(() => {
    if (!isBilling) {
      setIsBilling(true);
    }
  }, []);
  useEffect(() => {
    const hasProcedureCode = selectedProcedureCodes.length > 0;
    setHasSelectedProcedureCode(hasProcedureCode);
  }, [selectedProcedureCodes]);

  const noteAdded = (text) => {
    setClinicalNote(text);
    setToggleClinicalNote(false);
    setShow(true)
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scroll animation
    });
  };

  //---------------------------------------------- MODIFIERS --------------------------------------------------------//
  const assistantCodes = ["0008", "0009", "0076", "0029"];
  const timeFromToCodes = [
    "0023",
    "0029",
    "0033",
    "0039",
    "0090",
    "1211",
    "0016",
    "0011",
    "0011A",
  ];
  const toggleSidebar = (value) => {
    setIsSidebarOpenAddFavourites(value);
  };

  const weightCodes = ["0019", "0016", "0018"];
  const selectedWeight = selectedValues[selectedCode]?.weight || "";
  const selectedHeight = selectedValues[selectedCode]?.height || "";


  //----------------------------------------- VALIDATION SCHEMA --------------------------------------------------//
  const validationSchema = Yup.object({
    // email: Yup.string().email('Invalid email address').required('Email is required'),
    serviceCenter: !isServCAdIconClicked
      && Yup.string()
          .test("is-valid-plan", "Invalid Service Center Selected", (value) =>
            serviceCenterData.some(
              (option) => option.name.toUpperCase() === value?.toUpperCase()
            )
          )
          .required("Service Center is required")
          .matches(/\S/, "One non-whitespace required"),
    servCenterName:
      isServCAdIconClicked && isServCenterFlatListVisible
        ? Yup.string()
            .required("Service Center Name is required")
            .matches(/\S/, "One non-whitespace required")
        : Yup.string().notRequired(),

    servCenterType:
      isServCAdIconClicked && isServCenterFlatListVisible
        ? Yup.string().required("Service Center Type is required")
        : Yup.string().notRequired(),

    nameAndsurname:
      isRefDocAdIconClicked &&
      Yup.string()
        //.matches(/^[a-zA-Z]+\s+[a-zA-Z]+$/, 'Please enter both a First name and a Last name separated by a space')
        .required("Full name is required"),

    pracNumber:
      isRefDocAdIconClicked &&
      isRefDocAdIconClicked &&
      Yup.string()
        .matches(/^[0-9]+$/, "Only numbers are allowed")
        .required("Field is required"),

    specCode:
      isRefDocAdIconClicked &&
      Yup.string()
        .test("is-valid-plan", "Invalid Speciality", (value) =>
          filteredSpecialties.some(
            (option) => option.toUpperCase() === value.toUpperCase()
          )
        )
        .required("Speciality is required")
        .matches(/\S/, "One non-whitespace required"),

    servCenterName:
      isServCAdIconClicked &&
      Yup.string()
        .required("Field is required")
        .matches(/\S/, "One non-whitespace required"),

    serviceDate: Yup.string().required("Service Date is required"),

    servCenterType:
      isServCAdIconClicked && Yup.string().required("Field is required"),

    selectedICD10Codes: Yup.array()
      .min(1, "At least one ICD10 code is required")
      .test(
        "hasVWXYZ",
        "The S/T code diagnosis must be followed by an external cause code (V/W/X/Y)",
        function (value) {
          const hasSTICD10 = value.some(
            (code) =>
              code.icd10Code.charAt(0).toUpperCase() === "S" ||
              code.icd10Code.charAt(0).toUpperCase() === "T"
          );
          const hasVWXYZICD10 = value.some(
            (code) =>
              code.icd10Code.charAt(0).toUpperCase() === "V" ||
              code.icd10Code.charAt(0).toUpperCase() === "W" ||
              code.icd10Code.charAt(0).toUpperCase() === "X" ||
              code.icd10Code.charAt(0).toUpperCase() === "Y"
          );
          return !(hasSTICD10 && !hasVWXYZICD10);
        }
      ),
    selectedProcedureCodes: Yup.array().min(
      1,
      "At least one procedure code is required"
    ),

    assistantList: Yup.string().when(["selectedProcedureCodes"], {
      is: (codes) => {
        const result =
          !showAssistant &&
          codes.some((code) => assistantCodes.includes(code.code));
        return result;
      },
      then: (schema) =>
        schema
          .required("* Field required")
          .matches(/\S/, "One non-whitespace required")
          .test(
            "is-valid-assistant",
            "The input does not match any valid assistant code",
            (value) => filteredAssistants.some((item) => item.name === value)
          ),
      otherwise: (schema) => schema.notRequired(),
    }),
    nameandsurname: Yup.string().when("selectedProcedureCodes", {
      is: (codes) =>
        showAssistant &&
        codes.some((code) => assistantCodes.includes(code.code)),
      then: (schema) => schema.required("Full name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    specialty: Yup.string().when("selectedProcedureCodes", {
      is: (codes) =>
        showAssistant &&
        codes.some((code) => assistantCodes.includes(code.code)),
      then: (schema) =>
        schema
          .required("Specialty is required")
          .matches(/\S/, "One non-whitespace required")
          .test(
            "is-valid-speciality",
            "Please choose a valid specialty",
            (value) => filteredSpecialties.some((item) => item === value)
          ),
      otherwise: (schema) => schema.notRequired(),
    }),
    // fromTime: Yup.string().when("selectedProcedureCodes", {
    //   is: (codes) => codes.some((code) => timeFromToCodes.includes(code.code)),
    //   then: (schema) => schema.required("From time is required"),
    //   otherwise: (schema) => schema.notRequired(),
    // }),
    // toTime: Yup.string().when("selectedProcedureCodes", {
    //   is: (codes) => codes.some((code) => timeFromToCodes.includes(code.code)),
    //   then: (schema) => schema.required("To time is required"),
    //   otherwise: (schema) => schema.notRequired(),
    // }),
    weight: Yup.number().when("selectedProcedureCodes", {
      is: (codes) => codes.some((code) => weightCodes.includes(code.code)),
      then: (schema) =>
        schema
          .required("Weight is required") // Ensure this line is kept
          .test("weight-required", "Weight is required", function (value) {
            const { selectedProcedureCodes } = this.parent;
            const hasWeightCode = selectedProcedureCodes.some((code) =>
              weightCodes.includes(code.code)
            );
            return hasWeightCode ? value !== undefined && value !== "" : true;
          })
          .positive("Weight must be a positive number"),

      otherwise: (schema) => schema.notRequired(),
    }),
    height: Yup.number().when("selectedProcedureCodes", {
      is: (codes) => codes.some((code) => code.code === "0018"),
      then: (schema) =>
        schema
          .test("height-required", "Height is required", function (value) {
            const { selectedProcedureCodes } = this.parent;
            return selectedProcedureCodes.some((code) => code.code === "0018")
              ? !!value
              : true;
          })
          .positive("Height must be a positive number"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const toggleClinicalNoteSidebar = () => {
    setToggleClinicalNote(true);
    if (isSidebarOpenAddFavourites) {
      setIsSidebarOpenAddFavourites(false);
    }
  };
  const toggleSidebarAddToFavourites = () => {
    setIsSidebarOpenAddFavourites(true);
    setToggleClinicalNote(false);
  };

  const handleClinicalNoteSend = async (note) => {
    try {
      const result = await addClinicalNoteService.handleClinicalNote({
        Note: clinicalNote,
        PatientIdNo: patientData?.id || newManualPatientData?.patientID || "",
      });
      return result;
    } catch (error) {
      throw error;
    }
  };
  const closeFlatlists = () => {
    setIsServCenterFlatListVisible(false);
    setNappiCodeFlatListVisible(false);
    setIsICD10FlatListVisible(false);
    setProcedureCodeFlatListVisible(false);
    setIsRefDocFlatListVisible(false);
  };
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const {
        selectedICD10Codes,
        nappiCodes,
        serviceDate,
        assistantList,
        treatingDoctor,
      } = values;
      const administered_timestamp = currentDate?.toString() || "";
      const codes = selectedProcedureCodes
        .map((code, index) => ({
          line_nr: index + 1 + (reInvoice?.invoiceLines?.length || 0),
          code: code.code,
          quantity: code.quantity,
          diagnosis: selectedICD10Codes
            .map((icd10) => icd10.icd10Code)
            .join(","),
          administered_timestamp: currentDate?.toString() || "",
          description: code.description,
        }))
        .filter((code) => code.code !== undefined);

      const lines = [];
      const mergedLineItems = [...codes];
      mergedLineItems.forEach((item, procedureIndex) => {
        const { fromTime: lineFromTime, toTime: lineToTime } = times[
          item.code
        ] || { fromTime: "", toTime: "" };
        const procedureLine = {
          line_nr: lines.length + 1,
          procedure_or_nappi_code: item.code,
          quantity: item.quantity,
          icd10_codes: selectedICD10Codes.map((icd10) => icd10.icd10Code),
          assitant_doc_idnumbers: [],
          procedure_service_date: serviceDate,
          procedure_start_time: lineFromTime || fromTime || "",
          procedure_end_time: lineToTime || toTime || "",
          procedure_description: item.description,
          auth_no: authorizationNumber || "",
          favourite: false,
        };
        lines.push(procedureLine);
      });

      for (
        let procedureIndex = 0;
        procedureIndex < selectedNappiCodes.length;
        procedureIndex++
      ) {
        const nappiLine = {
          line_nr: lines.length + 1,
          procedure_or_nappi_code: selectedNappiCodes[procedureIndex].code,
          quantity: selectedNappiCodes[procedureIndex].quantity,
          icd10_codes: selectedICD10Codes
            .map((icd10) => icd10.icd10Code)
            .join(","),
          procedure_service_date: serviceDate,
          procedure_description: selectedNappiCodes[procedureIndex].name, //nappi code desc
          auth_no: authorizationNumber || "",
          favourite: false,
        };
        lines.push(nappiLine);
      }
      const invoice = {
        invoice_dates: serviceDate
          .split(",")
          .map((date) => ({ invoice_date: date.trim() })),
      };
      const invoiceDetails = {
        debtor: {
          debtor_dbid: "",
          debtor_source_id: patientData?.debtor_dbid || "",
          debtor_modified: false,
          debtor_title: "",
          debtor_initials: "",
          debtor_name: "",
          debtor_surname: "",
          debtor_idnumber: "",
          debtor_idtype: 0,
          debtor_vatnr: "",
          debtor_gender: "",
          debtor_language: "",
          debtor_dateofbirth: "",
          debtor_weight_kg: 0,
          debtor_height_cm: 0,
          debtor_nextofkin_name: "",
          debtor_nextofkin_cellnr: "",
          debtor_nextofkin_email: "",
          debtor_ma_schemename: patientData?.debtor_ma_schemename || "",
          debtor_ma_schemecode: "",
          debtor_ma_optionname: "",
          debtor_ma_optioncode: "",
          debtor_ma_number: patientData?.debtor_number || "",
          debtorContactDetails: {
            email_address: "",
            telephone: "",
            cell_number: "",
            fax_number: "",
            address_line1: "",
            address_line2: "",
            address_line3: "",
            postcode: "",
          },
        },
        patient: {
          is_debtor: newManualPatientData?.patientID ? true : false,
          patient_dbid: "",
          patient_source_id: patientData?.patient_dbid || "",
          patient_modified: goodx_modified || "",
          patient_auth: "",
          patient_title: "",
          patient_initials: "",
          patient_name:
            patientData?.name || newManualPatientData?.patientName || "",
          patient_surname:
            patientData?.surname || newManualPatientData?.patientSurname || "",
          patient_fullname: patientFullName || "",
          patient_idnumber:
            patientData?.id || newManualPatientData?.patientID || "",
          patient_type: "",
          patient_dateofbirth: patientData?.dob || "",
          patient_gender: "",
          patient_weight_kg: selectedWeight,
          patient_height_meter: selectedHeight,
          patient_nextofkin_name:
            patientData?.nextOfKinName || newManualPatientData?.nextofkin || "",
          patientNextofkinContactDetails: {
            email_address: "",
            telephone:
              patientData?.nextOfKinNumber ||
              newManualPatientData?.nextOfKinContact ||
              "",
            cell_number:
              patientData?.nextOfKinNumber ||
              newManualPatientData?.nextOfKinContact ||
              "",
            fax_number: "",
            address_line1: "",
            address_line2: "",
            address_line3: "",
            postcode: "",
          },
          patient_ma_schemename:
            patientData?.medical_aid_option ||
            newManualPatientData?.medicalAidSelectFromList ||
            "",
          patient_ma_schemecode: "",
          patient_ma_optionname:
            patientData?.medical_aid_plan ||
            newManualPatientData?.selectPlan ||
            "",
          patient_ma_optioncode: "",
          patient_ma_number:
            patientData?.medical_aid_number ||
            newManualPatientData?.medicalAidNumber ||
            "",
          patientContactDetails: {
            email_address:
              patientData?.patient_email || newManualPatientData?.email || "",
            telephone: patientData?.modifiedFields?.patientContactNumber
              ? patientData?.contact_number
              : newManualPatientData?.contactNumber || "",
            cell_number: patientData?.modifiedFields?.patientContactNumber
              ? patientData?.contact_number
              : newManualPatientData?.contactNumber || "",
            fax_number: "",
            address_line1: patientData?.address || "",
            address_line2: patientData?.address || "",
            address_line3: patientData?.address || "",
            postcode: "",
          },
          Attachments: [selectedFileData],
        },
        doctors: [
          {
            doctor_dbid:
           
              "",
            doctor_source_id:
              defaultTreatingDocID?.toString() || //DEFAULT TREATING DOCTOR ID
              selectedTreatingDoc?.id || //IF USER SELECTS A TREATING DOCTOR ID
              reInvoice?.dbDoctors?.[0]?.DoctorSourceID || //REINVOICE TREATING DOCTOR ID
              "",
            doc_type: 1,
            title: "",
            initials: "",
            name: "",
            qualification: "",
            own_code: "",
            speciality_code: "",
            council_nr: "",
            practice_nr: "",
            doctorContactDetails: {
              email_address: "",
              telephone: "",
              cell_number: "",
              fax_number: "",
              address_line1: "",
              address_line2: "",
              address_line3: "",
              postcode: "",
            },
          },
          {
            doctor_dbid: "",
            doctor_source_id: selectedRefDoc?.id?.toString() || "",
            //Doc Type = Referring Dr.
            doc_type: 2,
            title: selectedRefDoc?.title || "",
            initials: "",
            name: surnameAndName || selectedRefDoc?.name || "",
            qualification: "",
            own_code: "",
            speciality_code:
              specialityCode || selectedRefDoc?.speciality_code || "",
            council_nr: selectedRefDoc?.council_nr || "",
            practice_nr: practiceNumber || selectedRefDoc?.practice_nr || "",
            doctorContactDetails: {
              email_address: "",
              telephone: "",
              cell_number: "",
              fax_number: "",
              address_line1: "",
              address_line2: "",
              address_line3: "",
              postcode: "",
            },
          },
          {
            doctor_dbid: selectedAssistant?.id || "",
            doctor_source_id: assistantList || "",
            doc_type: 3, // Doc Type = Assistant.
            title: "",
            initials: "",
            name: nameSurname || selectedAssistant?.name || "",
            qualification: "",
            own_code: "",
            speciality_code:
              values.specialty || selectedAssistant?.speciality_code || "",
            council_nr: selectedAssistant?.council_nr || "",
            practice_nr: selectedAssistant?.practice_nr || "",
            doctorContactDetails: {
              email_address: "",
              telephone: "",
              cell_number: "",
              fax_number: "",
              address_line1: "",
              address_line2: "",
              address_line3: "",
              postcode: "",
            },
          },
        ],
        serviceCenter: {
          center_dbid:"",
          center_source_id:
            selectedServCenter?.id.toString() ||
            reInvoice?.dbCenter?.ServiceCenterName,
          ServiceCenterTypeDesc: servicecenterType || "",
          centerContactDetails: {
            email_address: "",
            telephone: "",
            cell_number: "",
            fax_number: "",
            address_line1: "",
            address_line2: "",
            address_line3: "",
            postcode: "",
          },
          description: reInvoice?.dbCenter?.ServiceCenterName || serviceCenterManualName || "",
        },
        invoice: {
          invoice_dates:
            multiDates.length > 0
              ? multiDates.map((date) => ({ invoice_date: date.trim() }))
              : serviceDate.map((date) => ({ invoice_date: date.trim() })),
          lines: lines,
          modifier: modifier,
        },
        Attachments: [],
      };
      // console.log("Sending data to GoodX:", invoiceDetails, "goodx");
      try {
        let responseData;
        responseData = await processBillingRequestService.handleSubmitBilling(
          invoiceDetails
        );
        const referenceNumber =
          responseData?.parm_extra === "None"
            ? responseData.message
            : responseData?.parm_extra || "";

        if (responseData && responseData.status === 0) {
          //send clinical note on submit
          await handleClinicalNoteSend(clinicalNote);
          navigate("/confirmationpageinvoice", {
            state: {
              referenceNumber,
              patientData,
              selectedICD10Codes,
              selectedProcedureCodes,
              lines,
              selectedFileData,
              patient_name,
              newManualPatientData,
            },
          });
        } else {
          //send clinical note on submit
          await handleClinicalNoteSend(clinicalNote);
          navigate("/confirmationpageinvoice", {
            state: {
              referenceNumber,
              patientData,
              selectedICD10Codes,
              selectedProcedureCodes,
              lines,
              patient_name,
              newManualPatientData,
            },
          });
        }
        return responseData;
      } catch (error) {
        console.error("Error:", error);
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <div>
      <Sidebar heading={"INVOICE DETAILS"}>
        <div className="container" onClick={closeFlatlists}>
          <NavigateBack text={patient_name} />
          <div>
            <Formik
              enableReinitialize={true}
              initialValues={{
                treatingDoctor: defaultTreatingDoc || "",
                referringDoctor:
                  reInvoice?.dbDoctors[1]?.DoctorName ||
                  selectedRefDoc?.name ||
                  "",
                serviceCenter:
                  reInvoice?.dbCenter?.ServiceCenterName ||
                  selectedServCenter?.name ||
                  "",
                authorizationNumber: reInvoice?.invoiceHeader?.AuthNo || "",
                nameandsurname: nameSurname,
                serviceDate: dateToDisplay,
                pracNumber: practiceNumber || "",
                nameAndsurname: surnameAndName,
                specCode: specialityCode,
                servCenterName: serviceCenterManualName,
                servCenterType: servicecenterType,
                email: "",
                mobileNumber: "",
                disciplineType: "",
                serviceDates: "",
                selectedICD10Codes:
                  selectedICD10Codes.length > 0 ? selectedICD10Codes : [],

                selectedProcedureCodes:
                  selectedProcedureCodes.length > 0
                    ? selectedProcedureCodes
                    : [],
                nappiCodes: "",
                assistantList: selectedAssistant?.name || "",
                specialty: searchTermSpeciality,
                clinicalNote: "",
                invoiceAdjustment: "",
                weight: selectedWeight,
                height: selectedHeight,
                fromTime: "",
                toTime: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, handleSubmit, isSubmitting, values }) => (
                <Form onSubmit={handleSubmit} className="search-padding">
                  <div className="row align-details">
                    {/* TREATING DOCTOR FIELD */}
                    <TreatingDoctor />
                    <ReferringDoctor />
                    <ServiceDateField
                      values={values}
                      setFieldValue={setFieldValue}
                      setIsSidebarOpenAddFavourites={toggleSidebar}
                    />
                  </div>
                  <ServiceCenter
                    serviceCenterType={values.servCenterType}
                    serviceCenterName={values.servCenterName}
                    serviceCenterDefault={values.serviceCenter}
                    isBatchRow={true}
                  />

                  <ICD10 reInvoice={reInvoice} />
                  <ProcedureCode
                    reInvoice={reInvoice}
                    setFieldValue={setFieldValue}
                    sidebar={false}
                  />
                  <NappiCodeSelector />

                  {/* Add code bundle to favourites */}
                  {hasSelectedProcedureCode && (
                    <AddButton
                      isSubmitting={isSubmitting}
                      func={() => toggleSidebarAddToFavourites()}
                      src={require("../../assets/icons/Favourite Icon.png")}
                      text={"Add Code Bundles to Favourites"}
                    />
                  )}
                  {/* Sidebar add favourites*/}
                  <BillingFavourites
                    selectedICD10Codes={selectedICD10Codes}
                    selectedNappiCodes={selectedNappiCodes}
                    selectedProcedureCodes={selectedProcedureCodes}
                    isSidebarOpenAddFavourites={isSidebarOpenAddFavourites}
                    setIsSidebarOpenAddFavourites={toggleSidebar}
                  />
                  <MultipleDatePicker dateCallBack={dateCallBack} />
                  <AddButton
                    isSubmitting={isSubmitting}
                    func={() => toggleClinicalNoteSidebar()}
                    src={require("../../assets/icons/Add Clinical Note to Invoice Icon.png")}
                    text={"Add Clinical Note to Invoice"}
                  />
                  <AddClinicalNote
                    clinicalNoteClicked={toggleClinicalNote}
                    patientID={
                      patientData?.id || newManualPatientData?.patientID || ""
                    }
                    closeClinicalNote={setToggleClinicalNote}
                    onNoteAdded={noteAdded}
                    isSubmit={false}
                  />
                  <ToastContainer position="top-end" className="p-3">
                    <Toast
                      show={show}
                      color="black"
                      autohide
                      bg="light"
                      onClose={() => setShow(false)}
                      delay={3000} // 3000ms = 3 seconds
                    >
                      <Toast.Header>
                        <strong className="me-auto">Clinical Note</strong>
                        <small>1s ago</small>
                      </Toast.Header>
                      <Toast.Body>Clinical note saved.</Toast.Body>
                    </Toast>
                  </ToastContainer>
                  <Loader loading={isSubmitting} />
                  <div className="d-flex justify-content-center">
                    <GenericButton
                      func={handleSubmit}
                      text={"Submit"}
                      disabled={isSubmitting}
                    />
                  </div>
                  <ErrorMessage
                    name="auth"
                    component="div"
                    className="error-text"
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default InvoiceStepTwo;

import axios from "axios";
import PROD_API from "../baseEndpoint";


const SaveReportService = (token) => {
  const saveReport = async (endDate, reportType, reportDocument) => { 
    try {

      const apiUrl = PROD_API + `OPS/reports/save_report_document?accessToken=${token}&selected_end_date=${endDate}&report_type=${reportType}`;
   
      const response = await axios.post(apiUrl,reportDocument);
      if (response.status === 200 && response.data) {
        // console.log("Response: ", response.data )

        return response.data
      } else {
        console.error("Failed to retrieve details. Status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  
  return { 
    saveReport
   };
};

export default SaveReportService;

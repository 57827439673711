import React from 'react';
import NewsBlock from './NewsBlock';
import Sidebar from '../Sidebar/Sidebar';
import ClientPortalTabOptions from './Reports/ClientPortalTabOptions';

const NewsFeed = () => {
  return (
    <Sidebar>
      <ClientPortalTabOptions/>
  <div className="container">
      <div className="row">
        <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
          <NewsBlock
            heading={"Why Every Medical Practice Needs a Billing Audit"}
            content={"Why every Medical Practice Needs a Billing Audit in today's complex healthcare environment, maintaining accurate and efficient billing processes is crucial for the success of"}
            image={require("../../assets/ClientPortal/clientPortalNews.png")}
          />
        </div>
        <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
          <NewsBlock
            heading={"Why Every Medical Practice Needs a Billing Audit"}
            content={"Why every Medical Practice Needs a Billing Audit in today's complex healthcare environment, maintaining accurate and efficient billing processes is crucial for the success of"}
            image={require("../../assets/ClientPortal/clientPortalNews.png")}
          />
        </div>
        <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
          <NewsBlock
            heading={"Why Every Medical Practice Needs a Billing Audit"}
            content={"Why every Medical Practice Needs a Billing Audit in today's complex healthcare environment, maintaining accurate and efficient billing processes is crucial for the success of"}
            image={require("../../assets/ClientPortal/clientPortalNews.png")}
          />
        </div>
      </div>
    </div>
    </Sidebar>

  );
}

export default NewsFeed;

import React, { useEffect, useState } from "react";
import FavouritesContent from "./FavouritesContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faEdit,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
const AllFavourites = ({
  filteredFavourites,
  handleEditIconClick,
  loadingState,
  openModal,
  icd10Codes,
  prodCodes,
  nappiCodes,
  favourites,
  currentKey,
  fetchFavouriteDetails,
  setCurrKey
}) => {
  const [expandedItems, setExpandedItems] = useState({});
  const [loading,setLoading] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      if (favourites.length > 0) {
        let favouriteDetailsExists = false;
        favourites.map((item, idx) => {
          if (item.id === currentKey && expandedItems[currentKey]) {
            return (favouriteDetailsExists = true);
          }
        });
        if (favouriteDetailsExists) {
          setLoading(true);
          for (let i = 1; i <= 3; i++) {
            await fetchFavouriteDetails(currentKey, i);
          }
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [currentKey, expandedItems]);
 
  const toggleAccordion = async (key) => {
    if (expandedItems[key]) {
      setExpandedItems((prevExpandedItems) => ({
        ...prevExpandedItems,
        [key]: !prevExpandedItems[key],
      }));
      return;
    }

    setExpandedItems((prevExpandedItems) => ({
      ...prevExpandedItems,
      [key]: !prevExpandedItems[key],
    }));

    if (!loadingState[key]) {
      setLoading(true);
      setCurrKey(key);
    }
  };
  return (
    <div className="code-table mt-4">
      <div id="accordion" className="scrollcontainerparent">
        {/* {Object.keys(filteredFavourites).map((key) => ( */}
        {Object.values(filteredFavourites).map((item) => (
          <div key={item.id}>
            <div
              className="code-header d-flex justify-content-between align-items-center mb-3"
              id={`heading-${item.id}`}
              key={item.id}
            >
              <span className="generalmarg">{item.description}</span>
              <div className="icon-buttons">
                {expandedItems[item.id] && (
                  <>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="edit-icon"
                      onClick={() => handleEditIconClick(item.id)}
                    />
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="edit-icon"
                      onClick={() => handleEditIconClick(item.id)}
                    />
                    <img
                      src={require("../../assets/trash.png")}
                      width={21}
                      height={21}
                      className="trash-icon"
                      onClick={openModal}
                      alt="Delete"
                    />
                  </>
                )}
                <FontAwesomeIcon
                  icon={expandedItems[item.id] ? faChevronDown : faChevronRight}
                  className="chevron-icon"
                  onClick={() => toggleAccordion(item.id)}
                  data-toggle="collapse"
                  data-target={`#collapse-${item.id}`}
                  aria-expanded={expandedItems[item.id]}
                  aria-controls={`collapse-${item.id}`}
                />
              </div>
            </div>
            {expandedItems[item.id] && (
              <div className="card-body bgcolor">
                <div className="d-flex mt-2 row">
                  <div className="d-flex flex-row">
                    {loading && !loadingState[item.id] ? (
                      <div className="d-flex justify-content-center w-100">
                        <i className="fas fa-spinner fa-spin"></i>
                      </div>
                    ) : (
                      <div className="flex-row justify-content-between w-100 align-items-start">
                        <FavouritesContent
                          expandedItems={expandedItems}
                          itemKey={item.id}
                          codes={icd10Codes}
                          title={"ICD10 Codes"}
                        />
                        <FavouritesContent
                          expandedItems={expandedItems}
                          itemKey={item.id}
                          codes={prodCodes}
                          title={"Procedure Codes"}
                        />
                        <FavouritesContent
                          expandedItems={expandedItems}
                          itemKey={item.id}
                          codes={nappiCodes}
                          title={"Nappi Codes"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllFavourites;

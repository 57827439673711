import React, { useState, useEffect, useCallback } from "react";
import { ErrorMessage, useFormikContext } from "formik";
import useProcedureCodesStore from "../state/ProcedureCodeStore";
import _ from "lodash";
const SearchProcedureCode = ({
  allProcedureData,
  modifierData,
  favouriteData,
  sidebar,
  handleSelectProcedures,
  filteredProcedureData,
  setFilteredProcedureData,
}) => {
  const [searchTermProcedureCode, setSearchTermProcedureCode] = useState("");
  const { setFieldValue } = useFormikContext();
  const {
    selectedProcedureCodes,
    setSelectedProcedureCodes,
    procedureCodeFlatListVisible,
    setProcedureCodeFlatListVisible,
  } = useProcedureCodesStore();
  const handleSelectProcedureCode = (item) => {
    const isCodeSelected = selectedProcedureCodes.some(
      (code) => code.code === item.code
    );
    let newSelectedCodes;

    if (isCodeSelected) {
      newSelectedCodes = selectedProcedureCodes.filter(
        (code) => code.code !== item.code
      );
    } else {
      const newItem = {
        code: item.code,
        quantity: 1,
        description: item.description || "",
      };
      newSelectedCodes = [...selectedProcedureCodes, newItem];
      if (item.isFavorite) {
        handleSelectProcedures(item, setFieldValue);
      }
    }

    setSelectedProcedureCodes(newSelectedCodes);
    setFieldValue("selectedProcedureCodes", newSelectedCodes);
    setProcedureCodeFlatListVisible(false); // Hide the procedure code list
    setSearchTermProcedureCode("");
  };
  const handleSearchProcedureCodeInput = (text) => {
    const combinedData = [...allProcedureData, ...modifierData];
    const favoritesList = favouriteData.itemList;

    const filteredProcedureData = combinedData
      .filter((item) => {
        const lowerCasedSearchTerm = text.toLowerCase();

        // Ensure `description` is a string before calling `.toLowerCase()`
        const itemDescription =
          typeof item.description === "string"
            ? item.description.toLowerCase()
            : "";

        return (
          (item.code && item.code === text) ||
          (item.code &&
            item.code.toLowerCase().includes(lowerCasedSearchTerm)) ||
          (item.modifierCode &&
            item.modifierCode.toLowerCase().includes(lowerCasedSearchTerm)) ||
          // Checking if description is a string before calling `.toLowerCase()`
          (itemDescription && itemDescription.includes(lowerCasedSearchTerm)) ||
          (favoritesList &&
            (Object.keys(favoritesList).some((key) =>
              key.toLowerCase().includes(lowerCasedSearchTerm)
            ) ||
              Object.values(favoritesList).some((value) =>
                value.toLowerCase().includes(lowerCasedSearchTerm)
              )))
        );
      })
      .sort((a, b) => {
        if (a.code === text) return -1;
        if (b.code === text) return 1;
        return 0;
      })
      .map(
        ({
          code,
          codeFavourite,
          description,
          modifierCode,
          modifierDescription,
        }) => ({
          code,
          description,
          codeFavourite,
          modifierCode,
          modifierDescription,
          isFavorite: favoritesList && code in favoritesList,
        })
      )
      .slice(0, 20);

    setFilteredProcedureData(filteredProcedureData);
  };
  const debouncedSearchProcedureCode = useCallback(
    _.debounce((text) => {
      handleSearchProcedureCodeInput(text);
    }, 250),
    [allProcedureData]
  );
  useEffect(() => {
    return () => {
      debouncedSearchProcedureCode.cancel();
    };
  }, [debouncedSearchProcedureCode]);
  const handleSearchCodeInputChange = (text) => {
    setSearchTermProcedureCode(text);
    if (text === "") {
      setSearchTermProcedureCode("");
    } else {
      debouncedSearchProcedureCode(text);
    }
    setProcedureCodeFlatListVisible(true);
  };
  return (
    <div className={`${!sidebar && "col-md-4"} text-font prodCodeMrgn`}>
      <label htmlFor="selectedProcedureCodes">
        Procedure Codes<span className="error-text">*</span>
      </label>
      <div style={{ position: "relative" }}>
        <input
          placeholder="Type Here to Search"
          type="text"
          value={searchTermProcedureCode}
          onChange={(e) => handleSearchCodeInputChange(e.target.value)}
          className="form-control"
          id="selectedProcedureCodes"
        />
        <i className="fas fa-search searchIcon"></i>
        {procedureCodeFlatListVisible && filteredProcedureData.length > 0 && (
          <div className="dropdown-list">
            <ul className="suggestions-list">
              {filteredProcedureData.map((item, index) => (
                <li
                  key={`${item.code || item.modifierCode}_${
                    item.description
                  }_${index}`}
                  onClick={() => handleSelectProcedureCode(item)}
                >
                  {item.code
                    ? typeof item.code === "object"
                      ? JSON.stringify(item.code)
                      : item.code
                    : "No Code"}{" "}
                  -
                  {item.description
                    ? typeof item.description === "object"
                      ? JSON.stringify(item.description)
                      : item.description
                    : "No Description"}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <ErrorMessage
        name="selectedProcedureCodes"
        component="div"
        className="error-text d-flex"
      />
    </div>
  );
};

export default SearchProcedureCode;

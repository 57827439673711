import React, { useState, useCallback, useEffect } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import ServCenterService from "../../services/Billing/ServiceCenterService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash/debounce";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import AuthorizationNumber from "./AuthorizationNumber";
import { useAuth } from "../../services/authentication/LoginService";
import useServiceCenterCodesStore from "../state/ServiceCenterCodeStore";

const ServiceCenter = React.memo(({
  serviceCenterType,
  serviceCenterName,
  serviceCenterDefault,
  hasAuth = true,
  isBatchRow = false,
  selectedServCenterId
}) => {
  const { setFieldValue } = useFormikContext();
  const { token } = useAuth();
  const [searchTextServCenter, setSearchServCenter] = useState(serviceCenterDefault || "");
  const [isVisible, setIsVisible] = useState(false);
  const {
    serviceCenterData,
    setServiceCenterData,
    setSelectedServCenter,
    setSelectedServCenterId,
    setservicecenterType,
    isServCenterFlatListVisible,
    setIsServCenterFlatListVisible,
    setisServCAdIconClicked,
    isServCAdIconClicked,
    setServiceCenterManualName
  } = useServiceCenterCodesStore();
  
  const [allServCenterData, setAllservCenterData] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const serviceCenter = ServCenterService(token);

  // Modified selection handler
  const handleSelectServCenter = (item) => {
    setSelectedServCenter(item);
    setSearchServCenter(item.name);
    setFieldValue("serviceCenter", item.name);
    setFieldValue("serviceCenterId", item.id);
    setSelectedServCenterId(item.id);
    setIsServCenterFlatListVisible(false);
    setisServCAdIconClicked(false);
    setservicecenterType("");
    
    localStorage.setItem('selectedServiceCenter', JSON.stringify(item));
  };

  // Modified search handler with lower character threshold
  const debouncedServiceCenterSearch = useCallback(
    debounce(async (searchText) => {
      if (searchText.length >= 2) { // Reduced from 5 to 2 characters
        try {
          setIsLoading(true);
          const serviceCenterData = await serviceCenter.ServiceCenterData();
          if (Array.isArray(serviceCenterData)) {
            const filteredData = serviceCenterData.filter((item) =>
              item.name.toLowerCase().includes(searchText.toLowerCase())
            );
            setServiceCenterData(filteredData);
          }
        } catch (error) {
          setError("Error fetching service center data.");
        } finally {
          setIsLoading(false);
        }
      }
    }, 300), // Reduced debounce time from 500ms to 300ms
    []
  );

  // Modified input handler
  const handleSearchInputServiceCenter = (text) => {
    setFieldValue("serviceCenter", text);
    setSearchServCenter(text);
    if (text.length >= 2) {
      debouncedServiceCenterSearch(text);
      setIsServCenterFlatListVisible(true);
    } else {
      setIsServCenterFlatListVisible(false);
    }
  };

  // Initial data fetch
  useEffect(() => {
    const fetchServiceCenters = async () => {
      try {
        setIsLoading(true);
        const data = await serviceCenter.ServiceCenterData();
        if (Array.isArray(data)) {
          setServiceCenterData(data);
          setAllservCenterData(data); // Keep a copy of all data
        }
      } catch (error) {
        setError("Error fetching service center data.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchServiceCenters();
  }, []);


  
    const handleSelectItem = (item) => {
      setIsVisible(false);
      setservicecenterType(item);
      setFieldValue("servCenterType", item);
    };

    const handlePlusIconClickServiceCenter = () => {
      setisServCAdIconClicked(true);
      setSearchServCenter("");
    };
    //------------------------------SERVICE CENTER --------------------------------------------------------//


    useEffect(() => {
      const fetchServiceCenters = async () => {
        try {
          setIsLoading(true);
          const serviceCenterData = await serviceCenter.ServiceCenterData();
          if (Array.isArray(serviceCenterData)) {
            setServiceCenterData(serviceCenterData);
          }
        } catch (error) {
          setError("Error fetching service center data.");
        } finally {
          setIsLoading(false);
        }
      };
      fetchServiceCenters();
    }, []);
   
    useEffect(() => {
      if (searchTextServCenter.length >= 5) {
        debouncedServiceCenterSearch(searchTextServCenter);
      }
    }, [searchTextServCenter, debouncedServiceCenterSearch, token]);
    const checkEqualServiceCenter = (text, setFieldValue) => {
      setServiceCenterManualName(text)
      const value = allServCenterData.some(
        (value) => value.name.toUpperCase() === text.toUpperCase()
      );
      if (value) {
        setFieldValue("servicecenterName", "");
        setServiceCenterManualName("")
        setFieldValue("serviceCenter", text);
        setSearchServCenter(text);
      }
    };
    return (
      <div className={isBatchRow ? "row align-details" : ""}>
        <div className="col-12 col-lg text-font">
        <label htmlFor="serviceCenter">
          Service Center<span className="error-text">*</span>
        </label>
        <div className="position-relative">
          <Field
            type="text"
            placeholder="Search Service Center"
            name="serviceCenter"
            value={serviceCenterDefault}
            onChange={(e) => handleSearchInputServiceCenter(e.target.value, setFieldValue)}
            className="form-control"
            id="serviceCenter"
            onClick={(e) => {
              e.stopPropagation();
              if (searchTextServCenter.length >= 2) {
                setIsServCenterFlatListVisible(true);
              }
            }}
          />
          <i className="fas fa-search searchIcon"></i>
          
          {searchTextServCenter.length >= 2 && serviceCenterData.length === 0 && (
            <FontAwesomeIcon
              icon={faPlus}
              className="plus-icon"
              onClick={() => {
                setisServCAdIconClicked(true);
                setSearchServCenter("");
              }}
            />
          )}

          {isServCenterFlatListVisible && serviceCenterData.length > 0 && (
            <div className="dropdown-list">
              {isLoading ? (
                <div className="p-2">Loading...</div>
              ) : (
                <ul>
                  {serviceCenterData.map((item) => (
                    <li
                      key={item.id}
                      onClick={() => handleSelectServCenter(item, setFieldValue)}
                      className="cursor-pointer hover:bg-gray-100 p-2"
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
        
        <ErrorMessage
          name="serviceCenter"
          component="div"
          className="error-text d-flex"
        />
        {error && <div className="error-text">{error}</div>}
      </div>

        {isServCAdIconClicked && (
          <>
            <div className="mb-3 text-font col-12 col-lg">
              <label htmlFor="servCenterName">Service Center Name *</label>
              <Field
                type="text"
                name="servCenterName"
                value={serviceCenterName}
                onChange={(e) => {
                  const text = e.target.value;
                  setFieldValue("servCenterName", text);
                  checkEqualServiceCenter(text, setFieldValue);
                
                }}
                className="form-control"
                id="servCenterName"
              />

              <ErrorMessage
                name="servCenterName"
                component="div"
                className="error-text d-flex"
              />
            </div>

            <div className="mb-3 text-font col-12 col-lg">
              <label htmlFor="servCenterType">Service Center Type:</label>
              <Field
                as="select"
                name="servCenterType"
                className="form-control"
                id="servCenterType"
                onChange={(e) => {
            
             
                  handleSelectItem(e.target.value);
                }}
              >
                <option value="">Select Type</option>
                <option value="Non-Facility">Non-Facility</option>
                <option value="Facility">Facility</option>
                <option value="Day Clinic">Day Clinic</option>
              </Field>
              <ErrorMessage
                name="servCenterType"
                component="div"
                className="error-text d-flex"
              />
            </div>
          </>
        )}
        {hasAuth && <AuthorizationNumber />}

        {/* for correct col padding */}
        <div className="mb-3 col"></div>
      </div>
    );
  }
);

export default ServiceCenter;

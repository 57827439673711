import React from "react";

const ReportItem = ({
  reportName,
  img,
  bgColor,
  click = () => {},
  optionalStyles,
  invertImage = false
}) => {
  return (
    <div
      onClick={click}
      className={`d-flex flex-column align-items-center justify-content-between text-center rounded p-4 pointer w-75 h-100  ${bgColor} ${optionalStyles}`}
    >
      <div>
        <img
          style={{ objectFit: "contain" }}
          width={150}
          alt=""
          src={img}
          className={`img-fluid ${invertImage ? "invertColor" : ''}`}
        
        />
      </div>
      <div>
        <span>{reportName}</span>
      </div>
    </div>
  );
};

export default ReportItem;

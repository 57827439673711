import { useFormikContext } from "formik";
import React, { useState } from "react";
import specialties from "../../assets/Codes/specialties.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CustomScroll from "../common/CustomScroll";
import useModifiersStore from "../state/ModifiersStore";
const Assistants = ({item, assistantVals }) => {
  const { setFieldValue, errors, touched, values } = useFormikContext();
  const [assistantFlatlist, setIsAssistantFlatlist] = useState(false);
  const [showPlusIcon, setshowPlusIcon] = useState(false);
  const [filteredSpecialties, setfilteredSpecialties] = useState(
    specialties.specialty
  );
  const [disciplineFlatlistVisible, setDisciplineFlatlistVisible] =
    useState(false);
  const [selectedAssistantList, setSelectedAssistantList] = useState(false);
  
  const assistantCodes = ["0008", "0009", "0076", "0029"];
  const {
    selectedAssistant,
    setSelectedAssistant,
    filteredAssistants,
    setFilteredAssistant,
    setNameSurname,
    nameSurname,
    showAssistant,
    setShowAddAssistant,
    searchTermSpeciality,
    setSearchTermSpeciality,
  } = useModifiersStore();
  const [assistantListText, setAssistantListText] = useState("");
  const checkEqualNameandSurname = (text, setFieldValue) => {
    //assistantVals
    const value = assistantVals.some(
      (value) => value.name.toUpperCase() === text.toUpperCase()
    );
    if (value) {
      setFieldValue("assistantList", text);
      // setSearchTermAssistantList(text);
      setShowAddAssistant(false);
      setFieldValue("nameandsurname", "");
      setFieldValue("specialty", "");
      setIsAssistantFlatlist(false);
      setshowPlusIcon(false);
      setShowAddAssistant(false);
    }
  };
  const handleSpeciality = (text, setFieldValue) => {
    setSearchTermSpeciality(text);
    if (text === "") {
      setFieldValue("specialty", "");
      setSearchTermSpeciality("");
    } else {
      setDisciplineFlatlistVisible(true);
      setFieldValue("specialty", text);
    }
  };
  const handleAssistantListText = (text) => {
    setFieldValue("assistantList", text);
    // setSearchTermAssistantList(text);
    const checkDirectlyEqual = assistantVals.some(
      (value) => value.name.toUpperCase() === text.toUpperCase()
    );
    if (checkDirectlyEqual) {
      setIsAssistantFlatlist(false);
      setshowPlusIcon(false);
      setShowAddAssistant(false);
      return;
    }
    let filteredData = [...assistantVals];

    if (text.length > 1) {
      let returnVals = filteredData.filter((item) => {
        return item.name.toLowerCase().includes(text.toLowerCase());
      });
      if (returnVals.length > 0) {
        setshowPlusIcon(false);
        setShowAddAssistant(false);
        setFilteredAssistant(returnVals);
      } else {
        setshowPlusIcon(true);
        setFilteredAssistant([]);
      }
    } else {
      setShowAddAssistant(false);
      setFilteredAssistant(filteredData);
    }
    setAssistantListText(text);
    setIsAssistantFlatlist(true);
  };
  const handleAssistantList = (item, setFieldValue) => {
    setSelectedAssistant(item);
    setFieldValue("assistantList", item.name);
    setIsAssistantFlatlist(false);
    setSelectedAssistantList(true);
  };
  return (
    <>
      {assistantCodes.includes(item.code) && (
        <div>
          <div>
            <div>
              <label>Assistant List</label>
              <div style={{ position: "relative" }}>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => {
                    // setFieldValue('assistantList', e.target.value);
                    handleAssistantListText(e.target.value, setFieldValue);
                  }}
                  value={selectedAssistant?.name || ""}
                  placeholder="Search Assistant"
                />
                {showPlusIcon && (
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="plus-icon"
                    onClick={() => {
                      setShowAddAssistant(true);
                      setFieldValue("assistantList", "");
                      // setSearchTermAssistantList("");
                    }}
                  />
                )}
              </div>
              {errors.assistantList && touched.assistantList && (
                <div className="error-text">{errors.assistantList}</div>
              )}
            </div>

            {assistantFlatlist && (
              <CustomScroll smallerScroll={false} maxHeight={"250px"}>
                <div className="d-flex flex-column justify-content-start align-items-start pointer">
                  {filteredAssistants.map((item, index) => (
                    <div
                      key={item.id}
                      className="p-1 "
                      onClick={() => handleAssistantList(item, setFieldValue)}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              </CustomScroll>
            )}
          </div>

          {showAssistant && (
            <div>
              <div style={{ position: "relative" }}>
                <label>Name & Surname</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => {
                    setNameSurname(e.target.value);
                    checkEqualNameandSurname(e.target.value, setFieldValue);
                  }}
                  value={nameSurname}
                  placeholder="Enter name and surname"
                  style={{ width: "100%" }}
                />
                {errors.nameandsurname && touched.nameandsurname && (
                  <div className="error-text">{errors.nameandsurname}</div>
                )}
              </div>

              <div style={{ position: "relative" }}>
                <label>Speciality</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) =>
                    handleSpeciality(e.target.value, setFieldValue)
                  }
                  value={searchTermSpeciality}
                  placeholder="Enter speciality"
                  style={{ width: "100%" }}
                />
                {errors.specialty && touched.specialty && (
                  <div className="error-text">{errors.specialty}</div>
                )}
              </div>
              {disciplineFlatlistVisible && filteredSpecialties.length > 0 && (
                <CustomScroll maxHeight={"250px"} smallerScroll={false}>
                  <div className="d-flex flex-column justify-content-start align-items-start pointer">
                    {filteredSpecialties.map((item, index) => (
                      <div
                        key={index}
                        style={{ padding: "0.5rem", cursor: "pointer" }}
                        onClick={() => {
                          setDisciplineFlatlistVisible(false);
                          setSearchTermSpeciality(item);
                          setFieldValue("specialty", item);
                        }}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </CustomScroll>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Assistants;

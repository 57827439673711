import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from "./Sidebar/Sidebar";
import GenericButton from "./GenericButton";

const AboutUsPage = () => {
  const handlePress = () => {
    window.open("https://Xpedient.co.za", "_blank");
  };

  return (
    <Sidebar heading={"ABOUT US"}>
      <div className="container bg-white mt-4 normal-txt">
        <div className="">
          <h2 className="about-txt">Financial control, billing and medical business management</h2>
          <h2 className="about-txt">Medical business Specialists</h2>
          <p className="text-start">
            Modern day doctors and specialists in private practice are spending an ever-increasing amount of time on business tasks that are far from their core competencies. Financial control, billing and business management are all subjects that might not get a doctor excited.
          </p>
          <p className="text-start"> 
            At Xpedient we feel quite the opposite. As medical business management, we are passionate about providing you with the support and advice that you need to free your time up to enable you to focus on your core passion providing professional healthcare.
          </p>
          <p className="text-start">
            Our total health care management services approach includes patient management on all financial matters with the same ethical standards you would apply to clinical matters.
          </p>
          <p className="text-start">
            At Xpedient, we go well beyond what any other medical billing company in the country offers by providing medical practitioners with a total practice management service, custom designed to service the needs of Doctors and Specialists.
          </p>
          <h3 className="about-txt">What makes Xpedient unique?</h3>

            <p className="text-start">A 98% proven medical debt collection rate, irrespective of the specialist type or rates charged.</p>
            <p className="text-start">Results-based fee structure.</p>
            <p className="text-start">Real-time receipting.</p>
            <p className="text-start">Monies paid directly into the doctors' own bank account.</p>
            <p className="text-start">Our clients have benefited from an increased turnover of up to 37%.</p>
            <p className="text-start">Considerable reduction in financial risk by streamlining and optimizing your current practice or association.</p>
          <h3 className="about-txt">Prevention is the cure.</h3>
          <p className="text-start">
            The key to proven success is through our pre-emptive approach to all aspects of medical practice management. This eliminates problems at the outset and includes the following efficiencies: correct allocation of medical aid codes from day one, handling medical aid claims, assisting with your PMB claims, providing you with practical management advice, helping to train your staff, minimizing your financial risk, and providing you with reports so that you remain in control of your business.
          </p>
          <p className="text-start">
            To assist your front-of-house staff, we provide management of patient queries through our dedicated and trained support staff. Critically, this improves both the patient experience and clears up financial queries quicker, resulting in faster payment. This allows you and your team to focus on the well-being of patients, while we take care of the financial management challenges to improve your cash flow.
          </p>
          <p className="text-start">
            Perhaps the greatest benefit is that you will have more time to practice your skills as a medical professional, and with a proven 98% collection rate, our total medical business management approach delivers vastly improved cash flow and more money in your bank account.
          </p>
            <GenericButton
            text={"Xpedient.co.za"}
            styling="btn profileColor mb-2"
            func={handlePress}
            />
        </div>
      </div>
      </Sidebar>
  );
};

export default AboutUsPage;

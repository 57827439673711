import React from "react";
import "../../styles/components/MobileMessage.scss";
import xpLogo from "../../assets/XpedientIcon.png";
import playStore from "../../assets/downloadPlayStore.jpg";
import appStore from "../../assets/downloadAppStore.jpg";
const MobileMessage = () => {
  return (
    <div>

      {/* //When the user does have the app but opens it on the web */}
    
      {/* <div className="mobile-message">
      <div className="d-flex">
        <img src={xpLogo} alt="" height={40} width={40} />
        <div className="d-flex flex-column">
          <small className="text-start ml-3 boldColor">Xpedient Portal</small>
          <small className="text-start  ml-3">Open in the Xpedient App</small>
        </div>
      </div>
      <button
        type="button"
        // onClick={() => proceed()}
        className="open"
      >
        Open
      </button>
      </div> */}

      {/* When the user doesnt have the app and is asked to download it */}
      <div className="d-flex flex-column justify-content-center p-2">
        <small className="text-center">
          For a better experience, download the mobile version of the Xpedient
          Portal:
        </small>
        <div className="mt-3">
          <a
            href="https://play.google.com/store/apps/details?id=co.za.xpedient"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="mr-2"
              alt=""
              src={playStore}
              width={120}
              heigth={120}
            />
          </a>
          <a
            href="https://apps.apple.com/app/apple-store/6479686896"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="ml-2"
              alt=""
              src={appStore}
              width={120}
              heigth={120}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileMessage;

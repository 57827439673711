import { Formik } from "formik";
import React from "react";
import { Form } from "react-router-dom";
import GenericInput from "../GenericInput";

const RateSetup = () => {
  return (
    <Formik>
      <div className="container">
        <div className="row">
          <div className="col-md-6 mb-3 mt-3">
           <h4 className="text-start">In-Room Rates</h4>
          </div>
          <div className="col-md-6 mb-3 mt-3">
          <h4 className="text-start">In-Hospital Rates</h4>
          </div>
          <div className="col-md-6 mb-3">
            <GenericInput
              description={"In-Rooms Medical Aid Patients"}
              onChange={() => {}}
              type="text"
              name={"irMedicalAidPatients"}
              required={false}
            />
          </div>
          <div className="col-md-6 mb-3">
            <GenericInput
              description={"In-Hospital Medical Aid Patients"}
              onChange={() => {}}
              type="text"
              required={false}
              name={"ihMedicalAidPatients"}
            />
          </div>
          <div className="col-md-6 mb-3">
            <GenericInput
              description={"In-Rooms Private Patients"}
              onChange={() => {}}
              type="text"
              required={false}
              name={"irPrivatePatients"}
            />
          </div>
          <div className="col-md-6 mb-3">
            <GenericInput
              description={"In-Hospital Private Patients"}
              onChange={() => {}}
              type="text"
              required={false}
              name={"ihPrivatePatients"}
            />
          </div>

          <div className="col-md-6 mb-3">
            <GenericInput
              description={"In-Room Fixed Rates & Combos"}
              onChange={() => {}}
              type="text"
              required={false}
              name={"irRatesandCombos"}
            />
          </div>
          <div className="col-md-6 mb-3">
            <GenericInput
              description={"In-Hospital Fixed Rates & Combos"}
              onChange={() => {}}
              type="text"
              required={false}
              name={"ihFixedRatesCombos"}
            />
          </div>
          <div className="col-md-6 mb-3">
            <GenericInput
              description={"International Patients Rooms Notes"}
              onChange={() => {}}
              type="text"
              required={false}
              name={"ipatientsRoomsNotes"}
            />
          </div>
          <div className="col-md-6 mb-3">
            <GenericInput
              description={"International Patients Hospital Notes"}
              onChange={() => {}}
              type="text"
              required={false}
              name={"ipatientsHospitalNotes"}
            />
          </div>
          <div className="col-md-6 mb-3">
            <GenericInput
              description={"In-Rooms Billing Policy"}
              onChange={() => {}}
              type="text"
              required={false}
              name={"irBillingPolicy"}
            />
          </div>
          <div className="col-md-6 mb-3">
            <GenericInput
              description={"In-Hospital Billing Policy"}
              onChange={() => {}}
              type="text"
              required={false}
              name={"ihBillingPolicy"}
            />
          </div>

          <div className="col-md-6 mb-3">
            <label className="form-label ">In-Rooms Notes</label>
            <textarea className="form-control " name={"irNotes"} />
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label ">In-Hospital Notes</label>
            <textarea className="form-control " name={"ihNotes"} />
          </div>
        </div>
      </div>
    </Formik>
  );
};

export default RateSetup;

import React from "react";
import SidebarButtons from "./SidebarButtons";
import useActiveTab from "../state/ActiveTab";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../services/authentication/UserContext";
const SidebarContainer = ({ expanded, subTaskCount }) => {
  const { customerAccessControl } = useContext(UserContext);
  const { storeActiveTab, setActiveTab } = useActiveTab();
  const [activeButton, setActiveButton] = useState(storeActiveTab);
  const navigate = useNavigate();
  const navigateFunction = (activeTab, route) => {
    setActiveTab(activeTab);
    navigate(route);
  };
  return (
    <div className="sidebar-container w-100">
      {/* Sidebar buttons */}
      <div className="marginbtn pointer">
        <div
          className="w-100"
          onClick={() =>
            navigateFunction("procedurecodesearch", "/procedurecodesearch")
          }
        >
          <SidebarButtons
            description={"Procedure Code"}
            img={require("../../assets/icd10img.png")}
            active={activeButton === "procedurecodesearch"}
            expanded={expanded}
          />
        </div>

        {(customerAccessControl?.Billing || customerAccessControl?.TicketBatchBilling) && (
          <div onClick={() => navigateFunction("patientsearch", "/patientsearch")}>
            <SidebarButtons
              description={"Billing"}
              active={activeButton === "patientsearch"}
              img={require("../../assets/home2.png")}
              expanded={expanded}
            />
          </div>
        )}

        {customerAccessControl?.Queries && (
          <div
            onClick={() =>
              navigateFunction("billingqueries", "/billingqueries")
            }
          >
            <SidebarButtons
              description={"Queries"}
              img={require("../../assets/home3.png")}
              active={activeButton === "billingqueries"}
              expanded={expanded}
            />
            {expanded && subTaskCount > 0 && (
              <span className="query-notification">{subTaskCount}</span>
            )}
          </div>
        )}

        {customerAccessControl?.History && (
          <div onClick={() => navigateFunction("history", "/billinghistory")}>
            <SidebarButtons
              description={"Clinical Notes"}
              img={require("../../assets/home4.png")}
              active={activeButton === "history"}
              expanded={expanded}
            />
          </div>
        )}

        {customerAccessControl?.ClientPortal && (
          <div
            onClick={() => navigateFunction("clientportal", "/clientportal")}
          >
            <SidebarButtons
              description={"Client Portal"}
              img={require("../../assets/home5.png")}
              expanded={expanded}
              active={activeButton === "clientportal"}
            />
          </div>
        )}
        {customerAccessControl?.FinancialDashboard && (
        <div
          onClick={() => navigateFunction("financialdash", "/financedashboard")}
        >
          <SidebarButtons
            description={"Financial Dashboard"}
            img={require("../../assets/home6.png")}
            active={activeButton === "financialdash"}
            expanded={expanded}
          />
        </div>
        )} 

        {customerAccessControl?.Settings && (
          <div onClick={() => setActiveButton("setting")}>
            <SidebarButtons
              description={"Settings"}
              img={require("../../assets/home7.png")}
              active={activeButton === "setting"}
              expanded={expanded}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarContainer;

import React, { useState } from "react";
import AddClinicalNoteService from "../services/ClinicalNotes/AddClinicalNoteService";
import SideNavbar from "./common/SideNavbar";
import { useAuth } from "../services/authentication/LoginService";
import { useNavigate } from "react-router-dom";

const AddClinicalNote = ({
  clinicalNoteClicked,
  patientID,
  closeClinicalNote,
  onNoteAdded,
  isSubmit = true,
}) => {
  const [loading, setLoading] = useState("");
  const navigate = useNavigate();
  const { token } = useAuth();
  const [clinicalNoteText, setClinicalNoteText] = useState("");
  const clinicalNote = AddClinicalNoteService(token);
  const [clinicalNoteErr, setClinicalNoteErr] = useState("");

  const addNote = () => {
    if (!clinicalNoteText.length > 0) {
      setClinicalNoteErr("Please Enter a Clinical Note to Proceed");
      return;
    }
    onNoteAdded(clinicalNoteText);
  };
  const addClinicalNote = async () => {
    try {
      setLoading(true);
      if (!clinicalNoteText.length > 0) {
        setClinicalNoteErr("Please Enter a Clinical Note to Proceed");
        return;
      }
      const response = await clinicalNote.handleClinicalNote({
        Note: clinicalNoteText,
        PatientIdNo: patientID,
      });
      navigate("/confirmationpage", {
        state: {
          message: response.message,
          refNo: response?.parm_extra,
        },
      });
      const parmExtra = response?.parm_extra || "";
      return parmExtra; // or return any other relevant data
    } catch (error) {
      console.error("Error adding note:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <SideNavbar
      heading={"ADD CLINICAL NOTE TO INVOICE"}
      notice={
        "Record and save patient encounter details on your device, securely stored in your clinical notes history for easy access and reference."
      }
      state={clinicalNoteClicked}
      proceed={isSubmit ? addClinicalNote : addNote}
      buttonText={
        loading ? <i className="fas fa-spinner fa-spin mr-2"></i> : "Save Note"
      }
      close={closeClinicalNote}
      headerContent={<div className="p-3">
      <textarea
        onChange={(e) => {
          if (e.target.value.length > 0) {
            setClinicalNoteErr("");
          } else {
            setClinicalNoteErr("Please Enter a Clinical Note to Proceed");
          }
          setClinicalNoteText(e.target.value);
        }}
        placeholder=""
        className="clinical-input"
      />
      {clinicalNoteErr && (
        <small className="text-danger">{clinicalNoteErr}</small>
      )}
    </div>}
    >
    </SideNavbar>
  );
};

export default AddClinicalNote;

import React from "react";
import GenericSearch from "../GenericSearch";

const SearchGuidance = () => {
  return (
    <div className="profileColor p-4">
      <h3>HOW CAN WE HELP YOU TODAY?</h3>
      <div className="d-flex justify-content-center align-center">
      <GenericSearch />
      </div>
    </div>
  ); 
};

export default SearchGuidance;

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../Sidebar/Sidebar";
import SavedReport from "../../../services/Reports/getSavedReport";
import { useAuth } from "../../../services/authentication/LoginService";
import GenericButton from "../../GenericButton";
import Loader from "../../../utils/Loader";
import ClientPortalTabOptions from "./ClientPortalTabOptions";
import CustomScroll from "../../common/CustomScroll";


const SavedReports = () => {
  const location = useLocation();
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const reports = location.state;
  const reportIndex = reports.reportIndex;
  const directoryName = reports.reports.SubDirectories[reportIndex].DirectoryName;
  const { getSavedReport } = SavedReport(token);

  // Create reportData object with proper format for monthly reports


  const handleExport = async (reportType) => {
    if (reportType === "VAT Report") {
      try {
        setLoading(true);
        const response = await handleExport(false);
      } catch (error) {
        console.error('Error during export:', error);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleDownload = async (filename) => {
    try {
      setLoading(true);
      const response = await getSavedReport(directoryName, filename);
      
      if (response && response.AttachmentData) {
        // Decode base64 data
        const byteCharacters = atob(response.AttachmentData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        
        const blob = new Blob([byteArray], { type: response.ContentType || 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Invalid response format');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleView = async (filename) => {
    try {
      setLoading(true);
      const response = await getSavedReport(directoryName, filename);
      
      if (response && response.AttachmentData) {
        // Create blob URL and open in new window
        const byteCharacters = atob(response.AttachmentData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: response.ContentType });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      }
    } catch (error) {
      console.error('Error viewing file:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (filename) => {
    const dateMatch = filename.match(/\d{8}/);
    if (!dateMatch) return "N/A";

    const dateStr = dateMatch[0];
    try {
      const year = dateStr.substring(0, 4);
      const month = dateStr.substring(4, 6);
      const day = dateStr.substring(6, 8);
      return `${year}-${month}-${day}`;
    } catch (error) {
      console.error("Error parsing date:", error);
      return "N/A";
    }
  };

  return (
    <Sidebar>
      <ClientPortalTabOptions />
      <div className="container mt-4">
        <div className="row border-bottom border-gray mb-3">
          <span className="text-start savedReportHeading text-uppercase">
            {reports.reportType} Reports
          </span>
        </div>
        <div className="row border-bottom border-black mb-3">
          <span className="text-start col-4 reportTableHeading">Report Name</span>
          <span className="text-start col-4 reportTableHeading">Date</span>
          <span className="text-start col-4"></span>
        </div>
        <div>
          <Loader loading={loading} />
          <CustomScroll maxHeight="60vh">
            {reports.reports.SubDirectories[reportIndex].Files.map(
              (item, idx) => (
                <div
                  className={`row ${idx % 2 === 0 ? "bg-white" : "bg-light"}`}
                  key={idx}
                >
                  <div className="d-flex justify-content-start align-items-center col-md-4 col-12">
                    <span className="text-start">
                      {reports.reportType} - {item}
                    </span>
                  </div>
                  <div className="d-flex justify-content-start align-items-center col-4">
                    <span className="text-start">{formatDate(item)}</span>
                  </div>
                  <div className="col-md-4 d-flex justify-content-end">
                      <GenericButton
                        func={() => handleDownload(item)}
                        text="Download"
                        bgColor="bg-queryFilter"
                        styling="p-2 col-4 rounded text-center"
                      />
                    {reports.reportType !== "VAT Report" && (
                      <GenericButton
                        func={() => handleView(item)}
                        text="View"
                        bgColor="bg-queryFilter"
                        styling="p-2 col-4 rounded text-center"
                      />
                    )}
                  </div>
                </div>
              )
            )}
          </CustomScroll>
        </div>
      </div>
    </Sidebar>
  );
};

export default SavedReports;
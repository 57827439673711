import React from "react";

const NewsBlock = ({ heading, image, content }) => {
  return (
    <div className="d-flex flex-column newsItem w-75">
      <img alt="" src={image} style={{ objectFit: "contain"}} />
      
      <h5 className="text-danger text-start p-3 ">{heading}</h5>
      <small className="text-start p-3 text-secondary">{content}</small>

      <small className="text-danger text-start p-3 mt-3 pointer">Read More...</small>
    </div>
  );
};

export default NewsBlock;

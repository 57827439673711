import { utils, writeFile, write } from 'xlsx';

import * as XLSX from 'xlsx';

export const exportToExcel = (sheets, filename) => {
  try {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Process each sheet
    sheets.forEach(sheet => {
      // Convert the data to a worksheet
      const ws = XLSX.utils.aoa_to_sheet(sheet.data);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, sheet.sheetName);

      // Add images if they exist
      if (sheet.images && sheet.images.length > 0) {
        if (!ws['!images']) ws['!images'] = [];
        sheet.images.forEach(image => {
          ws['!images'].push({
            ...image,
            type: 'picture',
            format: 'png'
          });
        });
      }
    });

    // Write the workbook and force a download
    XLSX.writeFile(wb, filename);
  } catch (error) {
    console.error('Error in exportToExcel:', error);
    throw error;
  }
};

export const exportToBase64 = (dataSheets) => {
  const wb = utils.book_new();

  dataSheets.forEach((sheetData) => {
    const { data, sheetName } = sheetData;
    const sheet = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, sheet, sheetName);
  });
  
  // Generate the Excel file in binary string format
  const binaryExcel = write(wb, { bookType: 'xlsx', type: 'binary' });
  
  // Convert binary string to a Base64 string
  const base64Excel = btoa(binaryExcel);
  
  return base64Excel; // Return the Base64 string
};




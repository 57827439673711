import React from 'react';
import '../../styles/components/login.scss';
import NavigationButton from '../NavigationButton.js';
import { useContext } from "react";
import { UserContext } from '../../services/authentication/UserContext.js';

const   BillingButtons = () => {
  const { customerAccessControl } = useContext(UserContext);

  return (
    <div className="responsive-flex">
      {customerAccessControl?.Billing && (
        <NavigationButton 
          header={"Submit Billing"} 
          color={"profileColor"} 
          img={require("../../assets/icons/Submit Billing Icon.png")}   
          route={"patientsearch"} 
          invert={true}
        />
      )}
      {/* {customerAccessControl?.TicketBatchBilling && ( */}
      <NavigationButton 
        header={"Batch Billing"} 
        color={"white"} 
        img={require("../../assets/icons/Submit Billing Icon.png")}  
        route={"batchbilling"} 
      />
    {/* )} */}
      <NavigationButton 
        header={"XP Invoice History"} 
        color={"red"} 
        route={"invoicehistory"} 
        img={require("../../assets/icons/XP Invoice History Icon.png")} 
        invert={true}   
      />
    </div>
  );
};

export default BillingButtons;

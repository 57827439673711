import "../../styles/components/InvoiceHistory.scss";
import React, { useState, useEffect } from "react";
import { useAuth } from "../../services/authentication/LoginService";
import { Formik } from "formik";
import * as Yup from "yup";
import QueriesHistoryService from "../../services/Queries/QueriesHistoryService";
import "../../styles/components/BillingQueries.scss";
import Sidebar from "../Sidebar/Sidebar";
import "../../styles/components/login.scss";
import "../../styles/components/InvoiceHistory.scss";
import NavigationButton from "../NavigationButton.js";
import { formatDate } from "../../utils/dateUtils.js";
import BillingQueriesTable from "./BillingQueriesTable.js";
import BillingQueriesToggler from "./BillingQueriesToggler.js";
import FromToDate from "../FromToDate.js";
import BillingQueriesDropdownData from "./BillingQueriesDropdownData.js";
import useClearStores from "../../utils/clearStores.js";
import Loader from "../../utils/Loader.js";

const BillingQueries = () => {
  const { token } = useAuth();
  const  clearStores = useClearStores()
  const [activeFilter, setActiveFilter] = useState("All");
  const [patientFound, setPatientFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rotate, setRotate] = useState(false);
  const [activeQueries,setActiveQueries] = useState("Billing")
  const [rotated, setRotated] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState(() => {
    const currentDate = new Date();
    const sixMonthsAgo = new Date(currentDate);
    sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
    return formatDate(sixMonthsAgo);
  });
  const [isResolvedQueries, setIsResolvedQueries] = useState(false);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [queryData, setQueryData] = useState([]);
  const [expandedCase, setExpandedCase] = useState(null);
  const [resolvedData, setResolvedData] = useState([]);
  
  useEffect(()=>{
    clearStores()
  },[])
  const toggleExpand = (caseID) => {
    setExpandedCase((prevExpanded) =>
      prevExpanded === caseID ? null : caseID
    );
  };
  const sortQueryDataByDate = (data) => {
    const sortedData = [...data];

    return sortedData.sort((a, b) => {
      return new Date(b.CaseCreatedDate) - new Date(a.CaseCreatedDate);
    });
  };
  const searchQueryHistory = async () => {
    try {
      setLoading(true);
      const taskType = activeQueries === "Billing" ? 1 : activeQueries === "Account"  ? 2 : 3;
      const taskStatus = 1;
      const result = await QueriesHistoryService(token).fetchQueriesHistory(
        selectedFromDate,
        selectedToDate,
        taskType,
        taskStatus
      );
      const sortedData = sortQueryDataByDate(result.Cases);
      // const invoiceTypes = sortedData.map(caseItem => caseItem.InvoiceType);
      setQueryData(sortedData);
      setFilteredData(sortedData);
      setPatientFound(sortedData.length > 0);
    } catch (error) {
      console.error("Error searching patient invoice history:", error);
      setPatientFound(false);
    } finally {
      setLoading(false);
    }
  };
  const searchQueryHistoryResolved = async () => {
    try {
      setLoading(true);
      const taskType = 1;
      const taskStatus = 5;
      const result = await QueriesHistoryService(token).fetchQueriesHistory(
        selectedFromDate,
        selectedToDate,
        taskType,
        taskStatus
        
      );
      const sortedData = sortQueryDataByDate(result.Cases); 
      if (sortedData.length > 0){
        setFilteredData((prev) => [...prev, ...sortedData]);
        setResolvedData(sortedData);
        setIsResolvedQueries(true);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error searching patient invoice history:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    searchQueryHistory();
    searchQueryHistoryResolved();
  }, [activeQueries]);

  const filterDates = (dateFrom, dateTo) => {
    if (dateFrom && dateTo) {
      filterData(dateFrom, dateTo, "", activeFilter);
    } else if (dateFrom) {
      filterData(dateFrom, "", activeFilter);
    } else {
      filterData("", dateTo, activeFilter);
    }
  };
  const renderAccordionDetails = (subTasks, caseData) => {
    // Count subtasks
    // const subtaskCounts = subTasks.reduce(
    //   (acc, item) => {
    //     if (
    //       item.SubtaskStatusDesc === "New Query" ||
    //       item.SubtaskStatusDesc === "Response Required"
    //     ) {
    //       acc.totalCount += 1;
    //     }
    //     if (item.SubtaskStatusDesc === "New Query") {
    //       acc.newQueryCount += 1;
    //     } else if (item.SubtaskStatusDesc === "Response Required") {
    //       acc.respondedCount += 1;
    //     }
    //     return acc;
    //   },
    //   { totalCount: 0, newQueryCount: 0, respondedCount: 0 }
    // );

    // Use `subtaskCounts` as needed here or pass it separately if needed for rendering

    // Only return the JSX content for rendering
    return (
      <BillingQueriesDropdownData subTasks={subTasks} caseData={caseData} />
    );
  };
  const handleSelectOption = (option) => {
    filterData(selectedFromDate, selectedToDate, option);
  };

  const filterData = (fromDate, toDate, option) => {
    if (option === "Resolved") {
      if (!resolvedData) return;
      let filteredData = [...resolvedData];
      if (fromDate && toDate) {
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);
        filteredData = filteredData.filter((item) => {
          const caseDate = new Date(item.CaseCreatedDate);
          return caseDate >= fromDateObj && caseDate <= toDateObj;
        });
      } else if (fromDate) {
        const fromDateObj = new Date(fromDate);
        const currentDate = new Date();
        filteredData = filteredData.filter((item) => {
          const caseDate = new Date(item.CaseCreatedDate);
          return caseDate >= fromDateObj && caseDate <= currentDate;
        });
      }
      return setFilteredData(sortQueryDataByDate(filteredData));
    } else {
      if (!queryData) return;
      let filteredData = [...queryData];
      if (option === "New Query") {
        filteredData = filteredData.filter((item) =>
          item.SubTasks.some(
            (subTask) => subTask.SubtaskStatusDesc === "New Query"
          )
        );
      } else if (option === "Responded to Xpedient") {
        filteredData = filteredData.filter((item) =>
          item.SubTasks.some(
            (subTask) => subTask.SubtaskStatusDesc === "Responded to Xpedient"
          )
        );
      } else if (option === "Response Required") {
        filteredData = filteredData.filter((item) =>
          item.SubTasks.some(
            (subTask) => subTask.SubtaskStatusDesc === "Response Required"
          )
        );
      }
      if (fromDate && toDate) {
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);
        filteredData = filteredData.filter((item) => {
          const caseDate = new Date(item.CaseCreatedDate);
          return caseDate >= fromDateObj && caseDate <= toDateObj;
        });
      } else if (fromDate) {
        const fromDateObj = new Date(fromDate);
        const currentDate = new Date();
        filteredData = filteredData.filter((item) => {
          const caseDate = new Date(item.CaseCreatedDate);
          return caseDate >= fromDateObj && caseDate <= currentDate;
        });
      }
      setFilteredData(sortQueryDataByDate(filteredData));
    }
  };
  const toggleTable = (index, item) => {
    if (index !== rotate) {
      setRotate(index);
      setRotated(true);
    } else {
      setRotated(!rotated);
    }
    toggleExpand(item.CaseID);
  };
  const toggleFilter = (toggleTo) => {
    setActiveFilter(toggleTo);
    handleSelectOption(toggleTo);
  };

  return (
    <div>
      <Sidebar heading={"BILLING QUERIES"}>
        <Formik
          initialValues={{ fromDate: "", toDate: "" }}
          validationSchema={Yup.object({})}
        >
          {({ values, setFieldValue }) => (
            <div className="container mt-4 search-padding">
              <div className="responsive-flex">
                <NavigationButton
                  header={"Billing Queries"}
                  color={activeQueries === "Billing" ? "profileColor" :  "gray"}
                  img={require("../../assets/icons/Billing Queries Icon.png")}
                  route={"billingqueries"}
                  invert={true}
                  handleClick={()=>setActiveQueries("Billing")}
                />
                <NavigationButton
                  header={"Account Queries"}
                  color={activeQueries === "Account" ? "profileColor" :  "gray"} //white
                  img={require("../../assets/icons/Account Queries Icon.png")}
                  // route={"batchbilling"}
                  handleClick={()=>setActiveQueries("Account")}
                />
                <NavigationButton
                  header={"Motivations"}
                  color={"gray"}
                  img={require("../../assets/icons/Billing Icon.png")}
                  // route={"batchbilling"}
                />
                <NavigationButton
                  header={"To be Handed Over List"}
                  color={"gray"} //red
                  // route={"invoicehistory"}
                  img={require("../../assets/query4.png")}
                  invert={true}
                />
                <NavigationButton
                  header={"General Queries"}
                  color={activeQueries === "General" ? "profileColor" :  "gray"} //darkred
                  // route={"invoicehistory"}
                  img={require("../../assets/query4.png")}
                  invert={true}
                  handleClick={()=>setActiveQueries("General")}
                />
              </div>
              <div className="row border-bottom border-dark">
                <div className="queriesHeader">
                  <FromToDate
                    changeDateFrom={(e) => {
                      const value = e.target.value;
                      setSelectedFromDate(value);
                      setFieldValue("fromDate", value);
                      filterDates(value, values.toDate);
                    }}
                    changeDateTo={(e) => {
                      const value = e.target.value;
                      setSelectedToDate(value);
                      setFieldValue("toDate", value);
                      filterDates(values.fromDate, value);
                    }}
                    values={values}
                  />
                    <BillingQueriesToggler
                      activeFilter={activeFilter}
                      toggleFilter={toggleFilter}
                    />
                </div>
              </div>
              <div className="col-12 mt-4">
                <div className="container">
                  {!loading ? (
                    patientFound || isResolvedQueries ? (
                      <BillingQueriesTable
                        sortQueryDataByDate={sortQueryDataByDate}
                        filteredData={filteredData}
                        toggleTable={toggleTable}
                        rotate={rotate}
                        rotated={rotated}
                        expandedCase={expandedCase}
                        renderAccordionDetails={renderAccordionDetails}
                      />
                    ) : (
                      <span>No Results</span>
                    )
                  ) : (
                 <Loader loading={loading}/>
                  )}
                </div>
              </div>
              <hr />
            </div>
          )}
        </Formik>
      </Sidebar>
    </div>
  );
};

export default BillingQueries;
